<script>
import getLabel from '@/api/getProfileStatusLabel'
import styles from '@/styles/config.sass'
import { aliasKeys } from '@/utils/formatters/aliasKeys'
import { EMPTY_ISSUES } from '@/constants'

import {
  STATE_ARCHIVED,
  STATE_INACTIVE,
  STATE_MANUAL_FAILED,
  STATE_MANUAL_PASSED,
  STATE_UNCHECKED,
} from '@/utils/stateBackendMapper'

const STATES = {
  STATE_ARCHIVED,
  STATE_INACTIVE,
  STATE_MANUAL_FAILED,
  STATE_MANUAL_PASSED,
  STATE_UNCHECKED,
}

export default {
  constants: {
    COLORS: {
      ACCENT: styles.COLOR__ACCENT,
      CLEAR: styles.COLOR__CLEAR,
      BLACK: styles.COLOR__DARK,
      LIGHTPINK: styles.COLOR__LIGHTPINK,
      BLUE: styles.COLOR__BLUE,
      BRIGHT_ACCENT: styles.COLOR__BRIGHTACCENT,
      LIGHT_ACCENT: styles.COLOR__LIGHTACCENT,
      LIGHTGREY: styles.COLOR__LIGHTGREY,
      GREY: styles.COLOR__TEXT_GREY,
      COLOR__RED: styles.COLOR__RED,
      COLOR__PRIMARY_YELLOW: styles.COLOR__PRIMARY_YELLOW,
      COLOR__GREEN: styles.COLOR__GREEN,
      COLOR__MID_LIGHT_GREY: styles.COLOR__MID_LIGHT_GREY,
      COLOR__DARK_NAVY_60: styles.COLOR__DARK_NAVY_60,
      COLOR__EXTRA_LIGHT_RED: styles.COLOR__EXTRA_LIGHT_RED,
      COLOR__LIGHT_RED: styles.COLOR__LIGHT_RED,
      COLOR__LIGHT_YELLOW: styles.COLOR__LIGHT_YELLOW,
      COLOR__F_YELLOW: styles.COLOR__F_YELLOW,
      COLOR__EXTRA_LIGHT_GREEN: styles.COLOR__EXTRA_LIGHT_GREEN,
      COLOR__NAVY: styles.COLOR__NAVY,
      COLOR_DARK_RED: styles.COLOR_DARK_RED,
    },
  },
  props: {
    type: {
      type: String,
    },
    styleLike: {
      type: String,
    },
    tooltip: {
      type: [String, Boolean],
    },
  },
  data: () => ({
    extraProps: {
      class: [],
    },
  }),
  computed: {
    tooltipText() {
      return this.getTooltipText(this.tooltip)
    },
    backgroundColor() {
      const fromStyles = this.$style[this.styleKey]
      return this.getBackgroundColor(this.styleKey) || fromStyles
    },
    fontWeight() {
      return this.getFontWeight(this.styleKey)
    },
    color() {
      const fromStyles = this.$style[this.styleKey]
      return this.getColor(this.styleKey) || fromStyles
    },
    label() {
      let label
      if (this.short) {
        const shortLabel = this.getShortLabel(this.type)
        label = shortLabel !== this.type ? shortLabel : this.getLabel(this.type)
      } else label = this.getLabel(this.type)
      if (this.shortLabel) {
        label = label?.replace('Risk Level', '')?.trim()
      }
      return this.type && label
    },
    extraText() {
      let extraText = null
      if (this.label && this.label.extraText) extraText = this.label.extraText
      return extraText
    },
    styleKey() {
      return this.styleLike || this.type
    },
  },
  methods: {
    getTooltipText(tooltip) {
      const text = {
        verified: 'This information has been verified',
        unverified: 'This information is unverified',
        delete: 'Delete',
        edit: 'Edit',
        view: 'View',
        'reset-password': 'Reset Password',
        action: false,
        ...aliasKeys(EMPTY_ISSUES, false),
        ...aliasKeys(['passed', 'clear'], 'No AML matches'),
        ...aliasKeys(
          ['404', 'partial'],
          'Did not pass KYC ruleset. Please review and verify manually.',
        ),
        media: 'Potential Adverse Media matches found. Please review.',
        pep: 'Potential PEP match found. Please review.',
        watchlist: 'Potential Watchlist match found. Please review.',
        sanction: 'Potential Sanction match found. Please review.',
        iwl: 'Internal Watchlist',
        'att-pht':
          'Facial recognition check has failed. Please review and verify manually.',
        'att-ocr': 'OCR check has failed. Please review and verify manually.',
        ...aliasKeys(
          ['att', 'att-idv'],
          'ID document authenticity check has failed. Please review and verify manually.',
        ),
      }
      if (tooltip === true) tooltip = this.type
      return text[tooltip] ?? tooltip ?? false
    },
    getShortLabel(label) {
      const labels = {
        clear: { icon: 'check-mark' },
        pep: 'PEP',
        watchlist: 'WL',
        iwl: { icon: 'watch', extraText: 'IW' },
        duplicate: 'DUP',
        blacklist: 'BL',
        device: 'Device',
        fraud: 'Fraud',
        media: 'AM',
        txn_fraud: 'Fraud Transactions',
        txn_aml: 'AML Transactions',
        txn_customer: 'Device/Customer',
        'att-face': 'Facial Duplicate',
        'digital-id': 'Digital ID',
        'att-pht': { icon: 'biometrics' },
        'att-ocr': { icon: 'ocr' },
        ...aliasKeys(['att', 'att-idv'], { icon: 'f-document' }),
        ...aliasKeys(['sanction', 'sanctions'], 'S'),
        ...aliasKeys(['partial', '404'], 'KYC'),
        ...aliasKeys(['error', '500', 'aml'], 'Timeout'),
        ...aliasKeys(['support-docs', 'SUPPORT-DOCS'], 'Document'),
        ...aliasKeys(['ATT-NCMP', 'att-ncmp'], 'Biometrics'),
        ...aliasKeys(['RSK', 'rsk'], 'Risk'),
      }
      return labels[label] || label || ''
    },
    getLabel(label) {
      return getLabel(label)
    },
    getBackgroundColor(bg) {
      const colors = {
        wait: '#dbecff',
        'Very High': this.COLORS.COLOR_DARK_RED,
        refer: this.COLORS.COLOR__LIGHT_YELLOW,
        iwl: this.COLORS.COLOR__NAVY,
        [STATES.STATE_ARCHIVED]: this.COLORS.COLOR__MID_LIGHT_GREY,
        [STATES.STATE_INACTIVE]: this.COLORS.COLOR__DARK_NAVY_60,
        'saturated-green-white': '#089b32',
        transparent: 'transparent',
        ...aliasKeys(['risk_low', 'Low', 'Unknown'], this.COLORS.COLOR__GREEN),
        ...aliasKeys(
          ['passed', 'clear', STATE_MANUAL_PASSED],
          this.COLORS.COLOR__EXTRA_LIGHT_GREEN,
        ),
        ...aliasKeys(['risk_unknown', STATE_UNCHECKED], '#edeff1'),
        ...aliasKeys(
          ['failed', STATE_MANUAL_FAILED],
          this.COLORS.COLOR__LIGHT_RED,
        ),
        ...aliasKeys(
          ['blacklist', 'duplicate', 'black', 'black-and-white'],
          'black',
        ),
        ...aliasKeys(
          ['risk_medium', 'Medium', 'support-docs', 'digital-id'],
          this.COLORS.COLOR__F_YELLOW,
        ),
        ...aliasKeys(
          [
            '404',
            '500',
            'error',
            'sanctions',
            'sanction',
            'saturated-red-white',
            'partial',
            'aml',
            'fraud',
            'att',
            'att-pht',
            'att-idv',
            'att-ocr',
            'ATT-NCMP',
            'att-ncmp',
            'att-face',
            'RSK',
            'rsk',
            'risk_high',
            'High',
            'risk_unacceptable',
            'risk_significant',
            'txn_fraud',
            'txn_aml',
            'txn_customer',
            'device',
          ],
          this.COLORS.COLOR__RED,
        ),
        ...aliasKeys(
          [
            'watchlist',
            'media',
            'saturated-orange-white',
            'pep',
            'pep-class-1',
            'pep-class-2',
            'pep-class-3',
            'pep-class-4',
            'pep-class-5',
          ],
          this.COLORS.COLOR__PRIMARY_YELLOW,
        ),
      }
      return colors[bg] || bg || 'transparent'
    },
    getFontWeight(fw) {
      const fontWeight = {
        'normal-weight': 'normal',
        'bold-weight': 'bold',
        ...aliasKeys(['clear', 'thin-weight', '100-weight'], '100'),
      }
      return fontWeight[fw] || fw || 'normal'
    },
    getColor(color) {
      const colors = {
        wait: this.COLORS.BLUE,
        ...aliasKeys(
          ['passed', STATE_MANUAL_PASSED, 'clear'],
          this.COLORS.COLOR__GREEN,
        ),
        ...aliasKeys(['failed', STATE_MANUAL_FAILED], this.COLORS.COLOR__RED),
        refer: this.COLORS.COLOR__F_YELLOW,
        'saturated-red': this.COLORS.ACCENT,
        'saturated-green': '#089b32',
        transparent: 'transparent',
        ...aliasKeys(
          [
            '404',
            '500',
            'aml',
            'att',
            'att-idv',
            'att-ncmp',
            'att-ocr',
            'att-pht',
            'att-face',
            'ATT-NCMP',
            'duplicate',
            'fraud',
            'iwl',
            'media',
            'partial',
            'pep',
            'risk_high',
            'risk_low',
            'risk_medium',
            'risk_significant',
            'risk_unacceptable',
            'rsk',
            'RSK',
            'saturated-green-white',
            'saturated-orange',
            'saturated-orange-white',
            'saturated-red-white',
            'black-and-white',
            'digital-id',
            'support-docs',
            'watchlist',
            STATE_INACTIVE,
            'txn_fraud',
            'txn_aml',
            'txn_customer',
            'device',
          ],
          'white',
        ),
        ...aliasKeys(['black', STATE_ARCHIVED], this.COLORS.BLACK),
        ...aliasKeys(['unchecked', 'risk_unknown'], '#8495a0'),
        grey: this.COLORS.GREY,
        'light-pink': this.COLORS.LIGHTPINK,
      }
      return colors[color] || color || 'white'
    },
  },
}
</script>
