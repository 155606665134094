import idNumber from './fields/idNumberField'
import common from '@frankieone/shared'
import mergeBlueprint from '@/utils/mergeBlueprint.js'
const { validator } = common
let blueprint = {
  fields: {
    idNumber: {
      ...idNumber({
        label: 'Description',
        placeholder: 'Description',
        removeSpaces: false,
      }),
      width() {
        return '100%'
      },
    },
  },
}
blueprint = mergeBlueprint('OTHER', blueprint)
export { blueprint }
