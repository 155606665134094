<template lang="pug">
    .form-builder( :class="getWrapperClasses()" :id="getWrapperId()" )
        slot
        template( v-for="(_, path) in allFields"  )
            //- document-form-builder( v-if="isFieldACompound(path)"
            //-     :class="[ `field-${path}`, 'is-compound-field', getFieldClasses(path) ]"
            //-     :id="getFieldId(path)"
            //-     :blueprint="getFieldData(path)"
            //-     v-bind="getCompoundRootProps(path)"
            //-     v-on="getCompoundRootEvents(path)" )

            div.f-input-space( v-if="getFieldComponent(path) && getFieldVisibility(path)"  :class="[ `field-${path}`, getFieldClasses(path)]" :id="getFieldId(path)" :style="{width: getFieldWidth(path)|| '50%'}")
                component( :is="getFieldComponent(path)" v-bind="getFieldPropsBundle(path)" v-on="getFieldListenerBundle(path)" :attach="`#${getFieldId(path)}`")
</template>

<style lang="sass" scoped>

.form-builder::v-deep
    & .field-full-name .content-container, .field-brideName .content-container, .field-groomName .content-container
        display: flex
        justify-content: space-between
        & .text-input
            width: calc(100%/3 - 15px)
.notVisible
    display: none
.f-input-space
    position: relative
    &::v-deep .v-menu__content
        @extend %custom-list-pick-input
        width: calc(100% - 20px)
        top: 64px !important
</style>

<script>
import ValidationEngineMixin from '@/mixins/validation/ValidationEngine.mixin'
import { makeRequiredValidator } from '@/utils/validators/basicValidators'

export default {
  name: 'document-form-builder',
  mixins: [ValidationEngineMixin],
  props: {
    level: {
      type: Number,
      default: 0,
    },
    blueprint: {
      required: true,
      type: Object,
    },
    idType: {
      required: false,
      type: String,
    },
    value: {
      required: true,
      type: Object,
    },
    disabled: Boolean,
    kycMethod: String,
    entityName: Object,
  },
  computed: {
    wrapperData() {
      // console.log(this.level, JSON.stringify(this.blueprint.wrapper));
      return this.blueprint.wrapper || {}
    },
    allFields() {
      return this.blueprint.fields || {}
    },
  },
  methods: {
    giveContext(func) {
      return func.bind(this)
    },
    runInContext(func) {
      return this.giveContext(func)()
    },

    // WRAPPER DATA
    getWrapperClasses() {
      const classes = []
      if (this.wrapperData.classes) {
        const mkClasses = this.wrapperData.classes.bind(this)
        classes.push(mkClasses())
      }
      return classes
    },
    getWrapperId() {
      if (!this.wrapperData.id) return ''
      const id = this.wrapperData.id.bind(this)
      return id()
    },
    getWrapperComponent() {
      if (!this.wrapperData.component) return 'div'
      return this.wrapperData.component
    },

    // FIELD DATA
    getCompoundRootEvents(path) {
      const blueprint = this.getFieldData(path)
      if (!blueprint || !blueprint.wrapper || !blueprint.wrapper.eventListeners)
        return {}
      const listeners = blueprint.wrapper.eventListeners.bind(this)
      return listeners()
    },
    getCompoundRootProps(path) {
      const blueprint = this.getFieldData(path)
      if (!blueprint || !blueprint.wrapper || !blueprint.wrapper.props)
        return {}
      const props = blueprint.wrapper.props.bind(this)
      return props()
    },
    getFieldData(path) {
      if (!this.allFields || !this.allFields[path]) return {}
      return this.allFields[path]
    },
    getFieldComponent(path) {
      // if path starts with "COMPOUND", it's a recursive
      if (this.isFieldACompound(path)) return 'document-form-builder'
      return this.allFields[path].component
    },
    isFieldACompound(path) {
      return path.match(/^COMPOUND/)
    },
    isFieldRequired(path) {
      const isRequired = this.allFields[path].required || (() => false)
      return this.runInContext(isRequired)
    },
    getFieldPropsBundle(path) {
      const mkProps = this.allFields[path].props
      const props = mkProps ? this.runInContext(mkProps) : {}

      if (this.isFieldACompound(path)) {
        props.level = this.level + 1
      }

      const bundle = {
        disabled: this.disabled,
        ...this.getFieldProps(path),
        ...props,
      }
      if (props.label && !this.isFieldRequired(path)) {
        bundle.label = `${bundle.label} (optional)`
      }
      return bundle
    },
    getFieldListenerBundle(path) {
      const mkListeners = this.allFields[path].eventListeners
      const listeners = mkListeners ? this.runInContext(mkListeners) : {}
      return {
        ...this.getFieldEvents(path),
        ...listeners,
      }
    },
    getFieldVisibility(path) {
      const shouldBeVisible = this.allFields[path].visibility || (() => true)
      return this.runInContext(shouldBeVisible)
    },
    getFieldClasses(path) {
      const mkClasses = this.allFields[path].classes || (() => '')
      return this.runInContext(mkClasses)
    },
    getFieldWidth(path) {
      const width = this.allFields[path].width || (() => '')
      return this.runInContext(width)
    },
    getFieldId(path) {
      const mkId = this.allFields[path].id || (() => '')
      return this.runInContext(mkId)
    },
    getValidator(path) {
      if (!this.allFields[path]) return () => true
      if (!this.getFieldVisibility(path)) return () => true
      const defaultValidators = this.allFields[path].validator || (() => true)
      let allValidators = defaultValidators
      if (this.isFieldRequired(path)) {
        allValidators = function (value, path) {
          const required = this.giveContext(makeRequiredValidator())
          const otherValidators = this.giveContext(defaultValidators)

          return required(value) && otherValidators(value, path)
        }
      }

      return this.giveContext(allValidators)
    },
  },
}
</script>
