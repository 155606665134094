import Vue from 'vue'
export function addScriptToHeader(src, options) {
  const { id, type, isAsync, defer, nonce } = {
    type: 'text/javascript',
    defer: false,
    async: true,
    ...options,
  }
  const _script = document.createElement('script')
  _script.type = type
  _script.async = isAsync
  _script.defer = defer
  _script.nonce = nonce // need to add nonce-{name} to the Content-Security-Policy to pass security policy
  _script.id = id
  _script.src = src
  const scriptElement = document.getElementsByTagName('script')[0]
  scriptElement.parentNode.insertBefore(_script, scriptElement)
}

export function addScript(attribute, content, callback) {
  const s = document.createElement('script')
  for (const attr in attribute) {
    s.setAttribute(attr, attribute[attr] ? attribute[attr] : null)
  }
  s.innerHTML = content
  s.onload = callback
  document.head.appendChild(s)
}
