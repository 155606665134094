import axios from 'axios'
import routeToURLMapper from '@/utils/routeToUrlMapper'
import config, { axiosConfigForNode } from './axiosConfig'
import { AuditLogV2 } from '@frankieone/shared'
import AuditEntry from '@/model/AuditEntry'
import extendObject from '@/utils/extendObject'
import { getDisplayTimeBuilder } from '@/utils/formatters/timeBuilder'
export async function getAuditReportList(entityId, options = {}) {
  const url = routeToURLMapper('ffportal_audit_list', { entityId })
  const { ABORT_CONTROL } = Object.assign({}, options)
  return axios
    .get(url, {
      ...axiosConfigForNode,
      cancelToken: ABORT_CONTROL.token,
      params: { entityId },
    })
    .then(res => whenLoaded(res.data))
}

const whenLoaded = data => {
  return data.records.map(r => {
    r = AuditLogV2.fromJSON(r)
    r.moment = {
      day: getDisplayTimeBuilder(r.ts, 'YYYYMMDD'),
    }
    return r
  })
}

function getPDF(entityId) {
  const url = routeToURLMapper('ffportal_audit_download_pdf')
  return axios.get(url, {
    responseType: 'arraybuffer',
    ...axiosConfigForNode,
    params: { entityId },
  })
}

export const downloadAuditReportPDF = entityId => {
  return getPDF(entityId) // API call
    .then(response => {
      const blob = new Blob([response.data], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'AuditReport.pdf'
      link.click()
    })
    .catch(err => console.log(err))
}
