import { ID_OPTIONS } from '@/utils/defaultsDocFormResolver.js'
export const prefixBase64 = mimeType => `data:${mimeType};base64`
export const mkDataUrl = (mimeType, file) => `${prefixBase64(mimeType)},${file}`
export function extractMimeFromString(string) {
  const mimePattern = /data:([a-zA-Z0-9]+\/.+);/
  const [_, mime] = string.match(mimePattern) || [null, '']
  return mime
}
export function extractMimeAndFileFromString(string) {
  const file = string.split(',')[1]
  const mimeType = extractMimeFromString(string)
  return { file, mimeType }
}
export const renderComponent = mimeType => {
  const fileType = mimeType.split('/')
  let componentType
  switch (fileType[0]) {
    case 'image':
      componentType = 'img'
      break

    case 'application':
      componentType = 'embed'
      break
    case 'text':
      componentType = 'iframe'
      break

    default:
      break
  }
  return componentType
}
export default function ({ localImageFile, file, mimeType, idType }) {
  const found = ID_OPTIONS.find(opt => opt.value === idType)
  const title = (found && found.label) || 'Document Preview'

  return new Promise(y => {
    if (localImageFile) {
      const reader = new FileReader()

      reader.onload = function (e) {
        const mimePattern = /data:([a-zA-Z0-9]+\/.+);/
        const [mimeString, file] = e.target.result.split(',')
        const [_, mime] = mimeString.match(mimePattern) || [null, '']

        if (mimeType.trim() !== mime) {
          console.error(
            `Mime type differs between backend (${mimeType}) and detected (${mime}). Using explicitly defined ${mimeType}.`,
          )
        }
        y({
          base64Encoded: file,
          mimeType,
          title,
          dataUrl: [{ file: mkDataUrl(mimeType, file), mimeType }],
        })
      }
      reader.readAsDataURL(localImageFile)
    } else {
      y({
        base64Encoded: file,
        mimeType,
        title,
        dataUrl: file,
      })
    }
  })
}

//From stackOverflow
export function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1])

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length)

  // create a view into the buffer
  const ia = new Uint8Array(ab)

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([ab], { type: mimeString })
  return blob
}

export function base64ToBlob(base64String, mimeString) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(base64String)

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length)

  // create a view into the buffer
  const ia = new Uint8Array(ab)

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([ab], { type: mimeString })
  return blob
}
