import idNumberField from './fields/idNumberField'
import documentNumberField from './fields/documentNumberField.js'
import regionField from './fields/regionField'
import TextInput from '@/components/inputs/TextInput'
import common from '@frankieone/shared'
import mergeBlueprint from '@/utils/mergeBlueprint.js'
const { validator } = common
let blueprint = {
  fields: {
    region: {
      ...regionField,
      visibility() {
        return this.value.country === 'AUS'
      },
      width() {
        return '50%'
      },
    },
    idNumber: idNumberField({
      label: 'Licence Number',
      placeholder: 'Licence Number',
    }),
    'extraData.licence_version': {
      component: TextInput,
      props() {
        return {
          placeholder: 'Licence version',
          label: 'Licence version',
          qa: 'licenceVer',
        }
      },
      visibility() {
        return this.value.country === 'NZL'
      },
      width() {
        return '50%'
      },
      required() {
        return this.value.country === 'NZL'
      },
      view() {
        return {
          title: 'Licence version',
          value: this.fieldValue,
        }
      },
    },
    'extraData.document_number': {
      ...documentNumberField(),
    },
    'extraData.home_country_fullname': {
      component: TextInput,
      props() {
        return {
          placeholder: 'Full Name as Shown on ID',
          label: 'Full Name as Shown on ID',
          qa: 'chineseFullname',
        }
      },
      visibility() {
        return this.value.country === 'CHN'
      },
      view() {
        return {
          title: 'Full Name as Shown on ID',
          value: this.fieldValue,
        }
      },
      required() {
        return this.value.country === 'CHN'
      },
    },
  },
}

blueprint = mergeBlueprint('DRIVERS_LICENCE', blueprint)
export { blueprint }
