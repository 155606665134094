import { getFraudCheckReport } from '@/api/apiFraud'
import { mkMutationName } from '@/store/helper'

export const setFraudReport = mkMutationName('set', 'Fraud', 'report')
export const clearCacheFRAUD = mkMutationName('clear', 'Fraud', 'allState')
export default {
  state: {
    fraudReport: false,
  },
  mutations: {
    [setFraudReport](state, data) {
      state.fraudReport = data
    },
    [clearCacheFRAUD](state) {
      state.fraudReport = false
    },
  },
  actions: {
    async getFraudCheckReport({ commit, getters }, payload) {
      const { entityId, ABORT_CONTROL, forceUpdate } = Object.assign(
        { ABORT_CONTROL: {} },
        payload,
      )
      if ((getters.isFraudFetchedData && !forceUpdate) || !entityId) return
      commit(setFraudReport, false)
      const promise = getFraudCheckReport(entityId, { ABORT_CONTROL })
        .then(data => commit(setFraudReport, data))
        .catch(e => commit(setFraudReport, null))

      return promise
    },
  },
  getters: {
    isFraudFetchedData: state => state.fraudReport !== false,
  },
}
