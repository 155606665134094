import TextInput from '@/components/inputs/TextInput'
import DateInput from '@/components/smart-inputs/DateInput'
import idNumberField from './fields/idNumberField'
import region from './fields/regionField'
import { DISPLAY_DATE_FORMAT, DISPLAY_YEAR_FORMAT } from '@/constants.js'
import fullNameField from './fields/fullNameField'
import mergeBlueprint from '@/utils/mergeBlueprint.js'
import { formatDate } from '@/components/builders/documentForm/DocumentViewTranform'
const group1 = ['ACT', 'NT', 'SA'] // idNumber, date of print, registerd name, certificate(optional)
const group2 = ['NSW', 'VIC'] //idNumber, Registration date (year), registerd name
const group3 = ['QLD', 'TAS'] // idNumber(optional),  registration date(date),  registerd name
const group4 = ['WA'] //idNumber, Registration date (year), registerd name, registration district

let blueprint = {
  fields: {
    region: {
      ...region,
      props() {
        return {
          placeholder: 'State or territory of registration',
          label: 'State or territory of registration',
          shouldDisplay: this.value.country === 'AUS',
          'include-states': [
            'NSW',
            'VIC',
            'TAS',
            'SA',
            'QLD',
            'ACT',
            'WA',
            'NT',
          ],
          qa: 'region',
        }
      },
      visibility() {
        return this.value.country === 'AUS'
      },
      view() {
        return {
          title: 'Registration State',
          value: this.state,
        }
      },
    },
    idNumber: {
      ...idNumberField({
        placeholder: 'Registration Number',
        label: 'Registration Number',
      }),
      visibility() {
        return this.value.country === 'AUS'
      },
      view() {
        return {
          title: 'Registration Number',
          value: this.fieldValue,
        }
      },
      required() {
        return false
      },
    },
    'extraData.registration_date': {
      component: DateInput,
      visibility() {
        return (
          !group1.includes(this.value.region) && this.value.country === 'AUS'
        )
      },
      props() {
        const stateRequireDateFormat = group3.includes(this.value.region)
        return {
          serialize: true,
          mask: !stateRequireDateFormat ? '####' : '####-##-##',
          minimumView: !stateRequireDateFormat ? 'year' : 'day',
          placeholder: !stateRequireDateFormat
            ? DISPLAY_YEAR_FORMAT
            : DISPLAY_DATE_FORMAT,
          label: 'Registration Date',
          qa: 'regYear',
          notShowDatePicker: true,
        }
      },
      width() {
        return '25%'
      },
      view() {
        const stateRequireDateFormat = group3.includes(this.value.region)
        const format = !stateRequireDateFormat
          ? DISPLAY_YEAR_FORMAT
          : DISPLAY_DATE_FORMAT
        return {
          title: 'Registration Date',
          value: this.fieldValue ? formatDate(this.fieldValue, format) : 'NA',
        }
      },
      required() {
        return this.value.country !== 'AUS'
      },
    },
    'extraData.certificate_date_of_print': {
      component: DateInput,
      required() {
        return true
      },
      props() {
        return {
          serialize: true,
          placeholder: DISPLAY_DATE_FORMAT,
          label: 'Date of Print',
          qa: 'dateOfPrint',
          notShowDatePicker: true,
        }
      },
      visibility() {
        return (
          group1.includes(this.value.region) && this.value.country === 'AUS'
        )
      },
      width() {
        return '25%'
      },
      view() {
        return {
          title: 'Date of Print',
          value: formatDate(this.fieldValue, DISPLAY_DATE_FORMAT),
        }
      },
    },
    ...fullNameField(),
    'extraData.certificate_number': {
      component: TextInput,
      props() {
        return {
          removeSpaces: true,
          placeholder: 'Certificate Number',
          label: 'Certificate Number',
          qa: 'certificateNumber',
        }
      },
      visibility() {
        return (
          group1.includes(this.value.region) && this.value.country === 'AUS'
        )
      },
      view() {
        return {
          title: 'Certificate Number',
          value: this.fieldValue,
        }
      },
    },
    'extraData.registration_district': {
      component: TextInput,
      props() {
        const label =
          this.value.country === 'AUS'
            ? 'Registration District'
            : 'Place Of Birth'
        const placeholder =
          this.value.country === 'AUS'
            ? 'Registration District'
            : 'Place Of Birth'

        return {
          label,
          placeholder,
        }
      },
      visibility() {
        return (
          (group4.includes(this.value.region) &&
            this.value.country === 'AUS') ||
          this.value.country === 'NZL'
        )
      },
      view() {
        return {
          title:
            this.value.country === 'AUS'
              ? 'Registration District'
              : 'Place Of Birth',
          value: this.state,
        }
      },
    },
  },
}
blueprint = mergeBlueprint('BIRTH', blueprint)
export { blueprint }
