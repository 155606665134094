import shared from '@frankieone/shared'
const documentCollection = shared.validator.document
// Initial config can be found in frankie-node-shared
// Adding the same config name in generic fields (e.g. idNumberField) will override it
// Adding the same config name in blueprint file will override it and will be used instead
export default function mergeBlueprint(documentType, blueprint) {
  if (!documentCollection[documentType]) return blueprint
  const clone = Object.assign({}, blueprint)
  for (const key in clone.fields) {
    clone.fields[key] = {
      ...documentCollection[documentType].fields[key],
      ...clone.fields[key],
    }
  }

  return clone
}
