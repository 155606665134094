import { blueprint as passportBlueprint } from './blueprints/passport'
import { blueprint as driversBlueprint } from './blueprints/driversLicense'
import { blueprint as visaBlueprint } from './blueprints/visa'
import { blueprint as medicareBlueprint } from './blueprints/medicare'
import { blueprint as immigrationBlueprint } from './blueprints/immigration'
import { blueprint as birthBlueprint } from './blueprints/birth'
import { blueprint as nameChangeBlueprint } from './blueprints/nameChange'
import { blueprint as marriageBlueprint } from './blueprints/marriage'
import { blueprint as vehicleBlueprint } from './blueprints/vehicle'
import { blueprint as citizenshipBlueprint } from './blueprints/citizenship'
import { blueprint as nationalIdBlueprint } from './blueprints/nationalId'
import { blueprint as taxIdBlueprint } from './blueprints/taxID'
import { blueprint as otherBlueprint } from './blueprints/other'
import categoryField from './blueprints/fields/documentCategoryField'
import { ID_TYPES } from '@/utils/defaultsDocFormResolver'
export { ID_TYPES }

/**
 * This class is a factory for Vue components that display specific doc types based on pre defined blueprints
 */
export default class DocumentFormFactory {
  constructor(builder) {
    // @param builder
    //      vue component used to render the blueprint
    //      all props and events will be passed to it
    // @props blueprint will be passed based on id type
    // @props idType will be passed directly from argument in make(idType)
    //
    this.builder = builder
  }
  make(documentType, options = {}) {
    const { overrideIdType } = Object.assign(
      {
        overrideIdType: false,
      },
      options,
    )
    let blueprint
    switch (documentType) {
      case ID_TYPES.PASSPORT:
        blueprint = passportBlueprint
        break
      case ID_TYPES.DRIVERS_LICENCE:
        blueprint = driversBlueprint
        break
      case ID_TYPES.VISA:
        blueprint = visaBlueprint
        break
      case ID_TYPES.MEDICARE_CARD:
        blueprint = medicareBlueprint
        break
      case ID_TYPES.IMMIGRATION:
        blueprint = immigrationBlueprint
        break
      case ID_TYPES.BIRTH_CERT:
        blueprint = birthBlueprint
        break
      case ID_TYPES.NAME_CHANGE:
        blueprint = nameChangeBlueprint
        break
      case ID_TYPES.MARRIAGE_CERT:
        blueprint = marriageBlueprint
        break
      case ID_TYPES.VEHICLE_REGISTRATION:
        blueprint = vehicleBlueprint
        break
      case ID_TYPES.CITIZENSHIP:
        blueprint = citizenshipBlueprint
        break
      case ID_TYPES.TAX_ID:
        blueprint = taxIdBlueprint
        break
      case ID_TYPES.NATIONAL_ID:
      case ID_TYPES.BANK_STATEMENT:
        blueprint = nationalIdBlueprint
        break
      case ID_TYPES.OTHER:
        blueprint = otherBlueprint
        break
    }
    if (!blueprint) return null //throw new Error(`DocumentFormFactory: unrecognized document type '${documentType}'`);
    blueprint = this.includeExtraFields(
      overrideIdType || documentType,
      blueprint,
    )
    return this.renderBlueprint(documentType, blueprint)
  }
  renderBlueprint(documentType, blueprint) {
    // eslint-disable-next-line
    const dir = this;
    return {
      /**
       * THIS IS A WRAPPER COMPONENT FOR BUILDER COMPONENT
       * blueprint is injected as a prop based on documentType
       * all props passed to this will be directly applied to builder
       */
      inheritAttrs: false,
      name: `${documentType}-form-factory`,

      render(h) {
        return h(
          dir.builder,
          {
            props: {
              blueprint,
              idType: documentType,
              ...this.$attrs,
            },
            on: {
              ...this.$listeners,
            },
          },
          this.$slots.default,
        )
      },
    }
  }
  includeExtraFields(docType, blueprint) {
    blueprint.fields['extraData.kyc_document_category'] = categoryField
    return blueprint
  }
}
