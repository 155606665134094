import {
  fetchMultipepResultList,
  updateMultipepMatchStatus,
} from '@/api/multipep'
import { mapMultipepMatchStatus } from '@/utils/apiHelpers/multipep'
import extendObject from '@/utils/extendObject'
import { mapAlertList } from '@/utils/backendAlertMapper'
import { fetchMultipepResultListDetail } from '@/api/multipep'
import { mkMutationName } from '@/store/helper'
import { normalizeLists } from '../../pages/applicantDetails/multipep/utils/ListingNormalizer.js'

export const setSearchDetails = mkMutationName(
  'set',
  'MultiPep',
  'SearchDetails',
)
export const setResultList = mkMutationName('set', 'MultiPep', 'ResultList')
export const clearCachePEP = mkMutationName('clear', 'MultiPep', 'ResultList')
import shared from '@frankieone/shared'
const AMLSearch = shared.model.AMLSearch
const AMLResult = shared.model.AMLResult
export default {
  state: {
    resultList: null,
    searchDetails: new AMLSearch(),
  },
  mutations: {
    [setSearchDetails](state, data) {
      state.searchDetails = data
    },
    [setResultList](state, data) {
      state.resultList = data
    },
    [clearCachePEP](state, data) {
      state.resultList = null
      state.searchDetails = new AMLSearch()
    },
  },
  actions: {
    async getMultipepResultList({ commit, getters }, payload) {
      const { entityId, ABORT_CONTROL } = Object.assign(
        { ABORT_CONTROL: {} },
        payload,
      )
      if (getters.isPEPFetchedData || !entityId) return

      const promise = fetchMultipepResultList(entityId, { ABORT_CONTROL })
        .then(allData => {
          allData.matches?.map(
            match =>
              (match.countries = match?.countries?.map(c => c.trim()) || []),
          ) || [] // sanitizing a little
          if (!allData) return commit(setResultList, null)
          const searchDetails = extendObject(new AMLResult(), allData)
          searchDetails.searchFuzziness =
            searchDetails.searchFuzziness &&
            parseFloat(searchDetails.searchFuzziness)
          if (
            searchDetails.searchFuzziness > 0 &&
            searchDetails.searchFuzziness < 1
          )
            searchDetails.searchFuzziness = searchDetails.searchFuzziness * 100
          commit(setSearchDetails, searchDetails)
          //Check if the result is empty, if yes just return empty array
          if (allData.matches.length < 1) return commit(setResultList, [])
          commit(setResultList, normalizeResults(allData.matches, entityId))
        })
        .catch(err => {
          commit(setResultList, null)
          throw err
        })
      commit(setResultList, promise)
      return promise
    },
    clearPEPTabData({ commit }) {
      commit(clearCachePEP)
    },
  },
  getters: {
    isPEPFetchedData: state => !(state.resultList === null),
    isPEPLoading: state => {
      return state.resultList !== null && !!state.resultList.then
    },
    resultListPEPResolve: state => {
      if (!state.resultList || state.resultList.then) return []
      else return state.resultList
    },
  },
}
const normalizeResults = (results, entityId) => {
  return results.map(result => {
    result = extendObject(new AMLResult(), result)
    result.matchStatus.status = mapMultipepMatchStatus(
      result.matchStatus.status,
    )
    result.matchStrength = parseFloat(result.matchStrength)
    result.issues = mapAlertList(result.pawsAlert)
    result.details = {
      ...result.details,
      ...normalizeLists(result.details),
    }
    const comment = result.comment
    result.comment = {
      by: result.matchStatus.by,
      text: comment,
    }

    return result
  })
}
