import styles from '@/styles/config.sass'

let counter = 0

function updateSpinnerLoadingState(spinner, $el, binding) {
  const ON = !!binding.value
  if (binding.modifiers.transparent) $el.style['opacity'] = ON ? '.7' : '1'
  if (!ON && spinner) {
    // $el.classList.remove("lds-ellipsis");
    spinner.style.display = 'none'
    // $el.classList.remove("is-loading-directive");

    // $el.removeChild(spinner);
    // delete $el.dataset.spinnerUid;
    // const parent = spinner.parentNode;

    // if (parent) parent.removeChild(spinner);
  } else if (ON && spinner) {
    spinner.style.display = 'block'
  }
}
function updateOverlayLoadingState(overlay, $el, binding) {
  const ON = !!binding.value
  if (!ON && overlay) {
    $el.removeChild(overlay)
  }
}
function mkSpinner(uid, binding) {
  const size = 12
  const spinner = document.createElement('div')
  spinner.classList.add('lds-ellipsis')
  spinner.classList.add('loading-spinner')
  spinner.id = uid
  spinner.innerHTML = [...new Array(4)].reduce(
    (string, i) =>
      string +
      `<div style="background-color: ${styles.COLOR__GREY}; height: ${size}px; width: ${size}px"></div>`,
    '',
  )
  spinner.style['zIndex'] = '99999'
  spinner.style['right'] = '0'
  spinner.style['top'] = '0'
  spinner.style['position'] = 'absolute'
  spinner.style['height'] = `${size}px`
  spinner.style['width'] = `${size * 5}px`
  if (binding.modifiers.small) {
    spinner.style['transform'] = 'scale(.5)'
  }
  if (binding.modifiers.center) {
    spinner.style['right'] = `calc(50% - ${(size * 5) / 2}px`
  }
  if (binding.modifiers.left) {
    spinner.style['right'] = 'initial'
    spinner.style['left'] = '0'
  }
  return spinner
}
function mkOverlay(uid) {
  const overlay = document.createElement('div')
  overlay.id = `overlay-${uid}`
  overlay.style['position'] = 'absolute'
  overlay.style['zIndex'] = '9999'
  overlay.style['right'] = '0'
  overlay.style['left'] = '0'
  overlay.style['top'] = '0'
  overlay.style['bottom'] = '0'
  overlay.style['height'] = '100%'
  overlay.style['width'] = '100%'
  overlay.style['opacity'] = '.9'
  overlay.style['backgroundColor'] = 'white'

  return overlay
}
export default {
  inserted: function (el, binding) {
    const uid = `spinner-${performance.now()}-${counter}`
    counter++

    el.dataset.spinnerUid = uid
    el.classList.add('has-loading-directive')
    el.style['position'] = 'relative'

    const spinner = mkSpinner(uid, binding)
    el.appendChild(spinner)
    updateSpinnerLoadingState(spinner, el, binding)

    // if (binding.modifiers.overlay) {
    //     const overlay = mkOverlay(uid);
    //     el.appendChild(overlay);
    //     updateOverlayLoadingState(overlay, el, binding);
    // }
  },
  update: function (el, binding) {
    const uid = el.dataset.spinnerUid

    const spinner = document.getElementById(uid)
    updateSpinnerLoadingState(spinner, el, binding)

    // if (binding.modifiers.overlay) {
    //     const overlay = document.getElementById(`overlay-${uid}`);
    //     updateOverlayLoadingState(overlay, el, binding);
    // }
  },
}
