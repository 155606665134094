<template lang="pug">
label-wrapper.text-input(
  :label="label",
  :for="id",
  :class="{ 'is-invalid': !validation, disabled, 'input-focus': isFocus }"
)
  input.basic-input-style(
    :class="{ 'is-invalid': !validation, isShowClearIcon }",
    v-if="mask",
    ref="textInput",
    disabled,
    autocomplete="nope",
    :type="type",
    :name="name",
    v-bind="attrs",
    :disabled="disabled",
    v-on="listeners",
    @keypress="onKeyPress",
    @input="emitValue",
    @blur="emitTrimValue",
    @focus="isFocus = true",
    :masked="false",
    :readonly="readOnly",
    v-mask="mask"
  )
  input.basic-input-style(
    :class="{ 'is-invalid': !validation, isShowClearIcon }",
    v-else-if="!isCutCopyDisabled",
    autocomplete="nope",
    :type="type",
    :name="name",
    ref="textInput",
    v-bind="attrs",
    :disabled="disabled",
    v-on="listeners",
    @keypress="onKeyPress",
    @blur="emitTrimValue",
    @focus="isFocus = true",
    :readonly="readOnly",
    @input="emitValue"
  )
  input.basic-input-style(
    :class="{ 'is-invalid': !validation, isShowClearIcon }",
    v-else,
    autocomplete="nope",
    :type="type",
    :name="name",
    ref="textInput",
    v-bind="attrs",
    :disabled="disabled",
    v-on="listeners",
    @keypress="onKeyPress",
    @blur="emitTrimValue",
    @focus="isFocus = true",
    :readonly="readOnly",
    @input="emitValue",
    @copy.prevent,
    @cut.prevent,
    data-recording-sensitive
  )
  simple-icon.clear-icon(
    v-if="isShowClearIcon",
    icon="f-fail",
    :overrideColor="COLOR__GREY",
    size="28px",
    padding="0px",
    @click="handleClear",
    data-testid="clear-text-icon"
  )
</template>
<style lang="sass" scoped>
.is-invalid .basic-input-style
    +input-style(error)
.input-focus .basic-input-style
    +input-style(focus)
.clear-icon
    position: absolute
    right: 5px
    top: calc(50% - 12px)
    cursor: pointer
    background-color: white
.isShowClearIcon
    padding-right: 30px!important
.disabled input
    cursor: not-allowed
    background-color: $COLOR__BACKGROUND_GREY
    color: $COLOR__DARK_NAVY_40
input
    width: 100%
.text-input
    cursor: text
    box-sizing: border-box
    position: relative
// .input.text
//     height: 120px
</style>

<script>
import InputMixin from './Input.mixin'
import LabelWrapper from '@/components/wrappers/LabelMessageWrapper'
import { mask } from 'vue-the-mask'
import { COLOR__GREY } from '@/styles/config.sass'
export default {
  mixins: [InputMixin],
  constants: {
    COLOR__GREY,
  },
  components: {
    LabelWrapper,
  },
  directives: { mask },
  props: {
    showErrorMessage: Boolean,
    notTrimData: Boolean, //Default will be trim the data,
    readOnly: Boolean,
    showClearIcon: Boolean,
    isCutCopyDisabled: Boolean,
    keypressPrevention: {
      type: Function,
      default: () => false,
    },
    type: {
      default: 'text',
    },
    name: String,
  },
  methods: {
    handleClear() {
      this.$refs.textInput.value = ''
      this.$emit('input', '')
      this.$emit('clear')
    },
    onKeyPress(evt) {
      const shouldPrevent = this.keypressPrevention(evt)
      if (shouldPrevent) {
        evt.preventDefault()
      }
    },
  },
  computed: {
    isShowClearIcon() {
      return !!(this.showClearIcon && this.value)
    },
  },
}
</script>
