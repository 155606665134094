import Vue from 'vue'
import ForbiddenModal from '@/components/widgets/ForbiddenModal'
import routeToUrl from '@/utils/routeToUrlMapper'
import axios from 'axios'
import { setSavedUrl } from '@/utils/urlStore'
import { getToken, getPageData } from '@/utils/pageDataStorage'

const checkIsLoginAndHasNoSession = () => {
  const loginURL = routeToUrl('ffportal_login')
  const currentURL = location.pathname
  const isLogin = loginURL === currentURL
  const hasSession = getToken()
  return isLogin && !hasSession
}
export const redirectToLogin = ({
  saveLocation = true,
}: { saveLocation?: boolean } = {}) => {
  if (checkIsLoginAndHasNoSession()) return
  const currentURL = location.pathname
  if (saveLocation) setSavedUrl(currentURL)

  localStorage.removeItem('token')
  localStorage.removeItem('pageData')

  //set the original url in localStorage before redirect back to login page, then later after login, we redirect back to the original url instead of onboarding
  const loginURL = routeToUrl('ffportal_login')
  window.location.href = loginURL
}
let isDup = false

export const displayForbiddenModal = () => {
  return new Promise((resolve, reject) => {
    if (isDup) return
    isDup = true
    new Vue({}).$modal.show(
      ForbiddenModal,
      {},
      { pivotX: 0.5, pivotY: 0.5, height: '429px', width: '940px' },
      {
        name: 'dynamic-modal',
        closed: () => {
          isDup = false //reset the isDup when modal is close
          resolve({})
        },
      },
    )
  })
}
export const axiosErrorHandler = async error => {
  if (axios.isCancel(error)) throw { aborted: true, payload: error }
  if (!error.response) throw error
  const response = error.response
  switch (response.status) {
    case 403:
      displayForbiddenModal()
      break
    case 401:
      redirectToLogin()
  }
  throw response
}

export const axiosResponseHandler = async response => {
  if (response.headers.token) {
    localStorage.setItem('token', response.headers.token)
  }
  return response
}
