import DocumentFormFactory from '@/components/builders/documentForm/DocumentFormFactory'
import DocumentFormBuilder from '@/components/builders/documentForm/DocumentFormBuilder'
import style from '@/styles/config.sass'
export const ID_TYPES = {
  DRIVERS_LICENCE: 'DRIVERS_LICENCE',
  PASSPORT: 'PASSPORT',
  VISA: 'VISA',
  MEDICARE_CARD: 'NATIONAL_HEALTH_ID',
  IMMIGRATION: 'IMMIGRATION',
  BIRTH_CERT: 'BIRTH_CERT',
  CITIZENSHIP: 'CITIZENSHIP',
  NAME_CHANGE: 'NAME_CHANGE',
  MARRIAGE_CERT: 'MARRIAGE_CERT',
  ATTESTATION: 'ATTESTATION',
  VEHICLE_REGISTRATION: 'VEHICLE_REGISTRATION',
  SELF_IMAGE: 'SELF_IMAGE',
  OTHER: 'OTHER',
  NATIONAL_ID: 'NATIONAL_ID',
  BANK_STATEMENT: 'BANK_STATEMENT',
  TAX_ID: 'TAX_ID',
}

export const ID_COUNTRY_CODES = {
  AUSTRALIA: 'AUS',
  NEW_ZEALAND: 'NZL',
  UNITED_KINGDOM: 'GBR',
  INDIA: 'IND',
  PHILIPPINES: 'PHL',
  CHINA: 'CHN',
  HONG_KONG: 'HKG',
  INDONESIA: 'IDN',
  MALAYSIA: 'MYS',
  SPAIN: 'ESP',
  SWEDEN: 'SWE',
  ITALY: 'ITA',
  BRAZIL: 'BRA',
  MEXICO: 'MEX',
  RUSSIA: 'RUS',
  TURKEY: 'TUR',
  SINGAPORE: 'SGP',
  SOUTH_AFRICA: 'ZAF',
  ARGENTINA: 'ARG',
  JORDAN: 'JOR',
  KUWAIT: 'KWT',
  OMAN: 'OMN',
  SAUDI_ARABIA: 'SAU',
  EGYPT: 'EGY',
  ROMANIA: 'ROU',
  VIETNAM: 'VNM',
  CAMBODIA: 'KHM',
  THAILAND: 'THA',
  UNITED_STATES_AMERICA: 'USA',
  DEFAULT: 'DEFAULT',
}

export const ID_COUNTRY_OPTIONS = [
  {
    label: 'Australia',
    value: ID_COUNTRY_CODES.AUSTRALIA,
  },
  {
    label: 'New Zealand',
    value: ID_COUNTRY_CODES.NEW_ZEALAND,
  },
  {
    label: 'United Kingdom',
    value: ID_COUNTRY_CODES.UNITED_KINGDOM,
  },
]
export const ID_SUB_TYPE = {
  [ID_TYPES.NATIONAL_ID]: {
    PAN_CARD: 'PAN',
    EPIC_CARD: 'EPIC',
  },
}
export const CATEGORY_PRIMARY_PHOTO = 'primary_photographic'
export const CATEGORY_PRIMARY_NONPHOTO = 'primary_nonphotographic'
export const CATEGORY_SECONDARY = 'secondary'

export const OTHER_DOC_TYPE_CATEGORY = [
  {
    value: CATEGORY_PRIMARY_PHOTO,
    label: 'Primary Photographic',
    color: style.COLOR__BLUE_LIGHT,
  },
  {
    value: CATEGORY_PRIMARY_NONPHOTO,
    label: 'Primary Non-Photographic',
    color: style.COLOR__ORANGE,
  },
  { value: CATEGORY_SECONDARY, label: 'Secondary', color: style.COLOR__VIOLET },
]
export const ID_OPTIONS = [
  {
    label: 'Drivers Licence',
    value: ID_TYPES.DRIVERS_LICENCE,
    available: [
      ID_COUNTRY_CODES.AUSTRALIA,
      ID_COUNTRY_CODES.NEW_ZEALAND,
      ID_COUNTRY_CODES.INDIA,
      ID_COUNTRY_CODES.CHINA,
    ],
  },
  {
    label: 'Passport',
    value: ID_TYPES.PASSPORT,
    available: [ID_COUNTRY_CODES.DEFAULT],
    // excluded: [ID_COUNTRY_CODES.NEW_ZEALAND]
  },
  {
    label: 'Visa',
    value: ID_TYPES.VISA,
    available: [ID_COUNTRY_CODES.AUSTRALIA, ID_COUNTRY_CODES.SINGAPORE],
  },
  {
    label: 'Immigration',
    value: ID_TYPES.IMMIGRATION,
    available: [ID_COUNTRY_CODES.AUSTRALIA],
  },
  {
    label: 'Birth Certificate',
    value: ID_TYPES.BIRTH_CERT,
    available: [ID_COUNTRY_CODES.AUSTRALIA, ID_COUNTRY_CODES.NEW_ZEALAND],
    // excluded: [ID_COUNTRY_CODES.NEW_ZEALAND]
  },
  {
    label: 'Medicare Card',
    value: ID_TYPES.MEDICARE_CARD,
    available: [ID_COUNTRY_CODES.AUSTRALIA],
  },
  {
    label: 'Citizenship Certificate',
    value: ID_TYPES.CITIZENSHIP,
    available: [ID_COUNTRY_CODES.AUSTRALIA, ID_COUNTRY_CODES.NEW_ZEALAND],
  },
  {
    label: 'Change Of Name Certificate',
    value: ID_TYPES.NAME_CHANGE,
    available: [ID_COUNTRY_CODES.AUSTRALIA],
  },
  {
    label: 'Marriage Certificate',
    value: ID_TYPES.MARRIAGE_CERT,
    available: [ID_COUNTRY_CODES.AUSTRALIA],
  },
  {
    label: 'Attestation',
    value: ID_TYPES.ATTESTATION,
    available: [],
  },
  {
    label: 'Motor Vehicle Registration',
    value: ID_TYPES.VEHICLE_REGISTRATION,
    available: [ID_COUNTRY_CODES.NEW_ZEALAND],
  },
  {
    label: 'Other',
    value: ID_TYPES.OTHER,
    available: [ID_COUNTRY_CODES.DEFAULT],
  },
  {
    label: 'National ID',
    value: ID_TYPES.NATIONAL_ID,
    available: [
      ID_COUNTRY_CODES.PHILIPPINES,
      ID_COUNTRY_CODES.CHINA,
      ID_COUNTRY_CODES.MALAYSIA,
      ID_COUNTRY_CODES.SWEDEN,
      ID_COUNTRY_CODES.ITALY,
      ID_COUNTRY_CODES.RUSSIA,
      ID_COUNTRY_CODES.TURKEY,
      ID_COUNTRY_CODES.JORDAN,
      ID_COUNTRY_CODES.KUWAIT,
      ID_COUNTRY_CODES.OMAN,
      ID_COUNTRY_CODES.SAUDI_ARABIA,
      ID_COUNTRY_CODES.EGYPT,
      ID_COUNTRY_CODES.ROMANIA,
      ID_COUNTRY_CODES.VIETNAM,
      ID_COUNTRY_CODES.THAILAND,
      ID_COUNTRY_CODES.INDONESIA,
      ID_COUNTRY_CODES.SINGAPORE,
    ],
  },
  {
    label: 'SSN',
    value: ID_TYPES.NATIONAL_ID,
    available: [ID_COUNTRY_CODES.UNITED_STATES_AMERICA],
  },
  {
    label: 'National ID (KTP)',
    value: ID_TYPES.NATIONAL_ID,
    available: [ID_COUNTRY_CODES.INDONESIA],
  },
  {
    label: 'National ID (DNI)',
    value: ID_TYPES.NATIONAL_ID,
    available: [ID_COUNTRY_CODES.SPAIN, ID_COUNTRY_CODES.ARGENTINA],
  },
  {
    label: 'National ID (CPF)',
    value: ID_TYPES.NATIONAL_ID,
    available: [ID_COUNTRY_CODES.BRAZIL],
  },
  {
    label: 'National ID (CURP/RFC)',
    value: ID_TYPES.NATIONAL_ID,
    available: [ID_COUNTRY_CODES.MEXICO],
  },
  // {
  //     label: "NRIC/FIN",
  //     value: ID_TYPES.NATIONAL_ID,
  //     available: [ID_COUNTRY_CODES.SINGAPORE]
  // },
  {
    label: 'National ID (Smart ID)',
    value: ID_TYPES.NATIONAL_ID,
    available: [ID_COUNTRY_CODES.SOUTH_AFRICA],
  },
  {
    label: 'Hong Kong ID',
    value: ID_TYPES.NATIONAL_ID,
    available: [ID_COUNTRY_CODES.HONG_KONG],
  },
  {
    label: 'Voter ID',
    value: ID_TYPES.NATIONAL_ID,
    available: [ID_COUNTRY_CODES.CAMBODIA],
  },
  {
    label: 'PAN Card',
    value: ID_TYPES.NATIONAL_ID,
    idSubType: ID_SUB_TYPE.NATIONAL_ID.PAN_CARD,
    available: [ID_COUNTRY_CODES.INDIA],
  },
  {
    label: 'Voter ID/EPIC',
    value: ID_TYPES.NATIONAL_ID,
    idSubType: ID_SUB_TYPE.NATIONAL_ID.EPIC_CARD,
    available: [ID_COUNTRY_CODES.INDIA],
  },
  {
    label: 'Bank Card',
    value: ID_TYPES.BANK_STATEMENT,
    available: [ID_COUNTRY_CODES.CHINA],
  },
  {
    label: 'Tax ID',
    value: ID_TYPES.TAX_ID,
    available: [ID_COUNTRY_CODES.DEFAULT],
  },
]
export function scanSorter(scan1, scan2) {
  const getSide = scan => scan.side || 'F'
  const getPoints = side => {
    let points = 0
    switch (side) {
      case 'F':
        points = 0
        break
      case 'B':
        points = 1
        break
      default:
        points = 2
        break
    }
    return points
  }

  return getPoints(getSide(scan1)) - getPoints(getSide(scan2))
}
export function scanDateSorter(scan1, scan2) {
  const getDate = scan => scan.scanCreated ?? 'ZZZ'
  return getDate(scan2).localeCompare(getDate(scan1))
}
export function getDisplayName(key) {
  let name = ''
  const option = ID_OPTIONS.find(opt => opt.value === key)

  name = option && option.label
  return name
}

// const mkDate = () => ({
//   day: '',
//   month: '',
//   year: ''
// })
export const getDocumentCategoryType = (mkycOptions, { country, idType }) => {
  const { countries } = mkycOptions
  if (!countries) return
  if (!countries[country]) country = 'default'
  const optionsForCountry = countries[country]
  const categories = Object.entries(optionsForCountry)

  let category = null
  categories.forEach(([categoryKey, categoryData]) => {
    const types = categoryData.types
    if (types && types.includes(idType)) category = categoryKey
  })
  return category
}
const mkDate = () => ''
export default function (idType, country, mkycOptions = {}) {
  let defaultData = {
    idNumber: null,
  }
  switch (idType) {
    case ID_TYPES.DRIVERS_LICENCE:
      defaultData = {
        idNumber: null,
        region: null,
        country: 'AUS',
        extraData: {
          document_number: null,
          kyc_document_category: getDocumentCategoryType(mkycOptions, {
            idType,
            country,
          }),
          home_country_fullname: null,
        },
      }

      if (country === ID_COUNTRY_CODES.NEW_ZEALAND) {
        defaultData.extraData.licence_version = null
      }
      break
    case ID_TYPES.PASSPORT:
      defaultData = {
        idNumber: null,
        country: 'AUS',
        extraData: {
          kyc_document_category: getDocumentCategoryType(mkycOptions, {
            idType,
            country,
          }),
        },
      }
      if (country === 'NZL') {
        defaultData.idExpiry = mkDate()
      }
      break
    case ID_TYPES.VISA:
      defaultData = {
        country: 'AUS',
        idNumber: null,
        extraData: {
          kyc_document_category: getDocumentCategoryType(mkycOptions, {
            idType,
            country,
          }),
        },
      }
      break
    case ID_TYPES.IMMIGRATION:
      defaultData = {
        country: 'AUS',
        idNumber: null,
        extraData: {
          kyc_document_category: getDocumentCategoryType(mkycOptions, {
            idType,
            country,
          }),
        },
      }
      break
    case ID_TYPES.BIRTH_CERT:
      defaultData = {
        country: 'AUS',
        idNumber: null,
        region: null,
        extraData: {
          registration_date: mkDate(),
          registration_district: null,
          certificate_number: null,
          certificate_date_of_print: mkDate(),
          registered_name_family: null,
          registered_name_first_given: null,
          registered_name_other_given: null,
          kyc_document_category: getDocumentCategoryType(mkycOptions, {
            idType,
            country,
          }),
        },
      }
      break
    case ID_TYPES.NAME_CHANGE:
      defaultData = {
        country: 'AUS',
        idNumber: null,
        region: null,
        extraData: {
          certificate_date_of_registration: mkDate(),
          certificate_number: null,
          certificate_date_of_print: mkDate(),
          registered_name_family: null,
          registered_name_first_given: null,
          registered_name_other_given: null,
          kyc_document_category: getDocumentCategoryType(mkycOptions, {
            idType,
            country,
          }),
        },
      }
      break
    case ID_TYPES.MARRIAGE_CERT:
      defaultData = {
        country: 'AUS',
        idNumber: null,
        region: null,
        extraData: {
          registration_year: mkDate(),
          marriage_date: mkDate(),
          certificate_date_of_registration: mkDate(),
          certificate_number: null,
          certificate_date_of_print: mkDate(),
          bride_name_family: null,
          bride_name_first_given: null,
          bride_name_other_given: null,
          groom_name_family: null,
          groom_name_first_given: null,
          groom_name_other_given: null,
          kyc_document_category: getDocumentCategoryType(mkycOptions, {
            idType,
            country,
          }),
        },
      }
      break
    case ID_TYPES.MEDICARE_CARD:
      defaultData = {
        country: 'AUS',
        idNumber: null,
        idSubType: 'G',
        idExpiry: mkDate(),
        middleName: '',
        extraData: {
          reference: null,
          kyc_document_category: getDocumentCategoryType(mkycOptions, {
            idType,
            country,
          }),
          display_middle_name: null,
        },
      }
      break
    case ID_TYPES.CITIZENSHIP:
      defaultData = {
        country: 'AUS',
        idNumber: null,
        extraData: {
          acquisition_date: mkDate(),
          primary_country: 'AUS',
          secondary_country: '',
          kyc_document_category: getDocumentCategoryType(mkycOptions, {
            idType,
            country,
          }),
        },
      }
      break
    case ID_TYPES.OTHER:
      defaultData = {
        country: '',
        idNumber: '',
        extraData: {
          kyc_document_category: CATEGORY_SECONDARY,
        },
      }
      break
    case ID_TYPES.ATTESTATION:
      defaultData = {
        country: '',
        idNumber: '',
        extraData: {
          kyc_document_category: getDocumentCategoryType(mkycOptions, {
            idType,
            country,
          }),
        },
      }
      break
    case ID_TYPES.VEHICLE_REGISTRATION:
      defaultData = {
        country: 'NZL',
        idNumber: '',
        extraData: {
          kyc_document_category: getDocumentCategoryType(mkycOptions, {
            idType,
            country,
          }),
        },
      }
      break
    case ID_TYPES.TAX_ID:
      defaultData = {
        country: 'NZL',
        idNumber: '',
        idSubType: '',
        extraData: {
          kyc_document_category: getDocumentCategoryType(mkycOptions, {
            idType,
            country,
          }),
        },
      }
      break
    case ID_TYPES.NATIONAL_ID:
    case 'EPIC':
    case 'PAN':
      defaultData = {
        country: '',
        idNumber: '',
        extraData: {
          home_country_fullname: '',
          kyc_document_category: getDocumentCategoryType(mkycOptions, {
            idType,
            country,
          }),
        },
      }
      if (idType === 'EPIC') defaultData.extraData.gender = null
      if (country === 'MEX') {
        defaultData.extraData = {
          ...defaultData.extraData,
          paternal_familyname: '',
          maternal_familyname: '',
        }
      }
      if (
        [ID_COUNTRY_CODES.THAILAND, ID_COUNTRY_CODES.INDONESIA].includes(
          country,
        )
      ) {
        defaultData.extraData = {
          ...defaultData.extraData,
          laser_code: '',
        }
      }
      break
    case ID_TYPES.BANK_STATEMENT:
      defaultData = {
        country: 'CHN',
        idNumber: '',
        extraData: {
          national_id: '',
          home_country_fullname: '',
          kyc_document_category: getDocumentCategoryType(mkycOptions, {
            idType,
            country,
          }),
        },
        idType: ID_TYPES.BANK_STATEMENT,
      }
      break
  }

  return defaultData
}
const documentFormFactory = new DocumentFormFactory(DocumentFormBuilder)
const allDocumentComponents = Object.values(ID_TYPES).reduce((obj, key) => {
  obj[key] = documentFormFactory.make(key)
  return obj
}, {})
function renderSlot() {
  return {
    render(h) {
      return h('div', this.$slots.default)
    },
  }
}
export function getDocumentComponent(idType) {
  return allDocumentComponents[idType] || renderSlot()
}
