export const DEFAULT_WRAP_CHARACTERS = ['.', '-']
export const WORD_BREAK_TAG = '<wbr>'
export function injectWordWrapTag(string, characters = []) {
  const arrayString = string.split('')
  if (characters.length === 0) characters = DEFAULT_WRAP_CHARACTERS
  for (let i = 0; i < arrayString.length; i++) {
    const char = arrayString[i]
    if (characters.includes(char)) {
      arrayString.splice(i, 0, WORD_BREAK_TAG)
      i++ // since array just grew by one element, increase counter
    }
  }
  return arrayString.join('')
}
