export default function isEmpty(data, options = {}) {
  const { deep } = options
  if (typeof data === 'number' || typeof data === 'boolean') {
    return false
  }
  if (typeof data === 'undefined' || data === null) {
    return true
  }
  if (typeof data.length != 'undefined') {
    return data.length === 0
  }
  let count = 0
  for (const i in data) {
    if (data.hasOwnProperty(i)) {
      if (deep) return isEmpty(data[i], { deep: true })
      count++
    }
  }
  return count === 0
}
