<template lang="pug">
    list-pick-input.docCategory(:options='options' :label='label' @input='change' :value='value' :disabled='disabled' :validation='validation')
        template( v-slot:default="{option: {label, value}, i}")
            .list-pick-wrapper
                .color-cursor(:style="{'backgroundColor': getColorFromValue(value) }")
                span {{label}}

</template>

<script>
import '@/components/icons/arrow-toggle'
import InputMixin from './Input.mixin'
import FreezingInput from '@/components/inputs/FreezingInput'
import ListPickInput from '@/components/inputs/ListPickInput.vue'
import { OTHER_DOC_TYPE_CATEGORY } from '@/utils/defaultsDocFormResolver.js'

export default {
  mixins: [InputMixin],
  components: {
    FreezingInput,
    ListPickInput,
  },
  data: () => ({
    options: OTHER_DOC_TYPE_CATEGORY,
  }),

  computed: {
    selectedLabel() {
      const selectOptions = this.options.find(el => el.value === this.value)
      return selectOptions && selectOptions.label
    },
  },
  methods: {
    change(value) {
      this.$emit('input', value)
    },
    getColorFromValue(value) {
      const option = this.options.find(opt => opt.value === value)
      const color = option && option.color
      return color
    },
  },
}
</script>

<style lang="sass" scoped>
.color-cursor
    height: 10px
    width: 10px
    border-radius: 50px
    margin-right: 5px
.docCategory::v-deep .input
    width: 240px!important
    padding-left: 5px!important
.docCategory::v-deep .input-like
    display: flex
    align-items: center
    padding-left: 12px!important
.list-pick-wrapper
    display: flex
    align-items: center
    span
        padding-left: 5px
.placeholder
    border: none
    background: transparent
    height: 100%
    padding: 0
.shadow
    height: 100%
    width: 100%
    position: absolute
    z-index: 9999
    top: 0
</style>
