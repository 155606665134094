export default class User {
  constructor() {
    this.id = null
    this.username = ''
    this.realName = ''
    this.email = ''
    this.isActive = false
    this.createdAt = {
      date: '',
      timezone_type: 0,
      timezone: 'UTC',
    }
    this.locked = false
    this.roles = []
  }
}
