import { NavigationGuardNext } from 'vue-router'
import mapRouteToUrl from '@/utils/routeToUrlMapper'
import { displayForbiddenModal, redirectToLogin } from '@/api/axiosInterceptors'
import {
  filterTxnPermissions,
  hasAnyTxnPermissions,
} from '@/pages/applicantDetails/transactions/TransactionsPermissionHelpers'

const checkIsIncludesPermission = (
  requiredPermissions,
  userAvailblePermission,
) => {
  return requiredPermissions.every(per => userAvailblePermission.includes(per))
}

export function guardForPermission({
  requiredPermissions,
  userAvailblePermission,
}: {
  requiredPermissions: string[]
  userAvailblePermission: string[]
}): boolean {
  if (!Array.isArray(userAvailblePermission) || !requiredPermissions)
    return false
  if (hasAnyTxnPermissions(requiredPermissions)) {
    const permissionTransactions = filterTxnPermissions(userAvailblePermission)
    return requiredPermissions.some(permission =>
      permissionTransactions.includes(permission),
    )
  }
  return checkIsIncludesPermission(requiredPermissions, userAvailblePermission)
}
type GuardWithoutFallback = {
  requiredPermissions: string[]
  userAvailblePermission: string[]
  next: NavigationGuardNext
}
type GuardWithFallback = GuardWithoutFallback & {
  fallBackRoute: string
  fallbackRoutePermissions: string[]
}
type GuardForRouteOptions = GuardWithoutFallback | GuardWithFallback
const checkHasFallback = (
  opt: GuardForRouteOptions,
): opt is GuardWithFallback =>
  Boolean((opt as GuardWithFallback).fallBackRoute) &&
  Boolean((opt as GuardWithFallback).fallbackRoutePermissions)
export function guardForRoute(options: GuardForRouteOptions) {
  const { requiredPermissions, userAvailblePermission, next } = options

  if (!next) throw 'Require function next() as a parameter'
  if (guardForPermission({ requiredPermissions, userAvailblePermission })) {
    next()
  } else {
    let fallBackRoute: string | null = null,
      fallbackRoutePermissions: string[] = []
    if (checkHasFallback(options))
      ({ fallBackRoute, fallbackRoutePermissions } = options)

    const passedFallbackPermissions = guardForPermission({
      requiredPermissions: fallbackRoutePermissions,
      userAvailblePermission,
    })
    const isCustomRedirect = fallBackRoute && passedFallbackPermissions
    const customRedirect = () => next(mapRouteToUrl(fallBackRoute))
    const loginRedirect = () => redirectToLogin({ saveLocation: false })
    const goNext = isCustomRedirect ? customRedirect : loginRedirect

    // alert(JSON.stringify({ isCustomRedirect, fallBackRoute, passedFallbackPermissions, fallbackRoutePermissions, userAvailblePermission }));
    displayForbiddenModal().then(goNext)
  }
}

export function checkPermissionValidForOnboarding(isPublic, pageData) {
  if (!isPublic || !pageData || !pageData.user) return false
  return guardForPermission({
    requiredPermissions: ['ffportal_onboarding_index'],
    userAvailblePermission: pageData.permissions,
  })
}
export function redirectToOnboarding() {
  window.location.href = mapRouteToUrl('ffportal_onboarding')
}
