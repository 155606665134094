<template lang="pug">
    filter-list-pick-input.state-picker(:attach="$el" :options="stateOptions", :value="{value}" @input="input($event)",
      :label="label" v-bind="attrs" :qa="qaLabel || 'state picker'")
</template>
<style lang="sass" scoped>
.state-picker
    position: relative
</style>
<script>
import FilterListPickInput from '@/components/inputs/FilterListPickInput.vue'
import SmartInputMixin from '@/components/smart-inputs/SmartInput.mixin'
import states from '@/data/states.json'

export default {
  mixins: [SmartInputMixin],
  components: { FilterListPickInput },
  constants: {},
  data() {
    return {
      states,
    }
  },
  props: {
    includeStates: Array,
    country: String,
    label: {
      type: String,
    },
  },
  computed: {
    stateOptions() {
      return this.states[this.country || 'AUS']
    },
  },
  methods: {
    input($event) {
      this.$emit('input', $event?.value)
    },
  },
}
</script>
