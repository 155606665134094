import { aliasKeys } from '@/utils/formatters/aliasKeys'

export const STATE_FAILED = 'failed'
export const STATE_PASSED = 'passed'
export const STATE_FAIL = 'fail'
export const STATE_PASS = 'pass'
export const STATE_MANUAL_FAILED = 'fail_manual'
export const STATE_MANUAL_PASSED = 'pass_manual'
export const STATE_NEEDS_ATTENTION = 'refer'
export const STATE_WAITING_ON_CUSTOMER = 'wait'
export const STATE_UNCHECKED = 'unchecked'
export const STATE_ARCHIVED = 'archived'
export const STATE_INACTIVE = 'inactive'

export default function stateBackend(state) {
  if (typeof state !== 'string') {
    return state
  }
  if (state) {
    const frontend = {
      ...aliasKeys(
        ['pass', 'passed', 'checked_success', 'checked_success_clear'],
        {
          type: STATE_PASSED,
          label: 'Passed',
        },
      ),
      ...aliasKeys(
        [
          'review',
          'refer',
          'checked_partial_success',
          'checked_success_with_notes',
        ],
        {
          type: STATE_NEEDS_ATTENTION,
          label: 'Refer',
        },
      ),
      ...aliasKeys(
        [
          'no_match',
          'fail',
          'failed',
          'check_stopped',
          'checked_failed',
          'not_supported',
          'unprocessable',
          'checking',
        ],
        { type: STATE_FAILED, label: 'Failed' },
      ),
      ...aliasKeys(['wait', 'waiting'], {
        type: STATE_WAITING_ON_CUSTOMER,
        label: 'Waiting',
      }),
      ...aliasKeys(['fail_manual', 'manual_fail'], {
        type: STATE_MANUAL_FAILED,
        label: 'Manually Failed',
      }),
      ...aliasKeys(['pass_manual', 'manual_pass'], {
        type: STATE_MANUAL_PASSED,
        label: 'Manually Failed',
      }),
      unchecked: { type: STATE_UNCHECKED, label: 'Unchecked' },
    }
    return { ...frontend[state.toLowerCase()], key: state.toLowerCase() }
  }
  return { type: null, label: null }
}

export function sortStatus(a, b, desc) {
  const STATUS_SORT = {
    [STATE_PASSED]: 1,
    [STATE_MANUAL_PASSED]: 2,
    [STATE_NEEDS_ATTENTION]: 3,
    [STATE_FAILED]: 4,
    [STATE_MANUAL_FAILED]: 5,
    [STATE_WAITING_ON_CUSTOMER]: 6,
  }

  a = typeof a === 'string' ? stateBackend(a) : a
  b = typeof b === 'string' ? stateBackend(b) : b
  const aString = STATUS_SORT[a.type] || 0
  const bString = STATUS_SORT[b.type] || 0

  // wtf is this?? among other crazy ilegible shit, seems to be using booleans as integers.
  // Did I do this? I hate it. Whoever read this in the future, please kill the responsible
  return (desc ? -1 : 1) * ((aString > bString) - (aString < bString))
}
