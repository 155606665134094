import { aliasKeys } from '@/utils/formatters/aliasKeys'

export type ApplicantStringFilters = {
  nameFilter: string
  entityIdFilter: string
}

export type ApplicantArrayFilters = {
  riskStatusFilter: string[]
  matchStatusFilter: string[]
  assigneeFilter: string[]
  amlStatusFilter: string[]
  entityTypeFilter: string[]
  profileNameFilter: string[]
  txnFraudRiskLevelFilter: string[]
  txnAMLRiskLevelFilter: string[]
  createdFilter: string[]
  caseOpenedFilter: string[]
}

export type ApplicantQueryStringFilters = {
  riskStatusFilter: string
  matchStatusFilter: string
  assigneeFilter: string
  amlStatusFilter: string
  entityTypeFilter: string
  profileNameFilter: string
  txnFraudRiskLevelFilter: string
  txnAMLRiskLevelFilter: string
}

export const STRING_FIELDS_MAP: ApplicantStringFilters = {
  ...aliasKeys(['nameFilter', 'entityIdFilter'], ''),
}

export const ARRAY_FIELDS_MAP: ApplicantArrayFilters = {
  ...aliasKeys(
    [
      'matchStatusFilter',
      'riskStatusFilter',
      'amlStatusFilter',
      'assigneeFilter',
      'entityTypeFilter',
      'profileNameFilter',
      'txnFraudRiskLevelFilter',
      'txnAMLRiskLevelFilter',
    ],
    [],
  ),
  createdFilter: ['', ''],
  caseOpenedFilter: ['', ''],
}

export const splitQueryBySeparator = ({
  query,
  separator,
}: {
  query: ApplicantQueryStringFilters
  separator: string
}) => {
  const splitString = string => (string ? string.split(`${separator}`) : [])
  const {
    riskStatusFilter,
    matchStatusFilter,
    assigneeFilter,
    amlStatusFilter,
    entityTypeFilter,
    profileNameFilter,
    txnFraudRiskLevelFilter,
    txnAMLRiskLevelFilter,
  } = query

  return {
    riskStatusFilter: splitString(riskStatusFilter),
    matchStatusFilter: splitString(matchStatusFilter),
    assigneeFilter: splitString(assigneeFilter),
    amlStatusFilter: splitString(amlStatusFilter),
    entityTypeFilter: splitString(entityTypeFilter),
    profileNameFilter: splitString(profileNameFilter),
    txnFraudRiskLevelFilter: splitString(txnFraudRiskLevelFilter),
    txnAMLRiskLevelFilter: splitString(txnAMLRiskLevelFilter),
  }
}

export type ApplicantColumns =
  | 'type'
  | 'name'
  | 'profile-status'
  | 'issues'
  | 'risk-level'
  | 'risk-fraud'
  | 'risk-aml'
  | 'created'
  | 'case-opened'
  | 'last-updated'
  | 'recipe'
  | 'monitoring'
  | 'assignee'
  | 'extra-actions'

export const getApplicantListVisibleColumns = (stage: string): string[] => {
  const visibleColumns: {
    default: ApplicantColumns[]
    onboarding: ApplicantColumns[]
    monitoring: ApplicantColumns[]
    transactions: ApplicantColumns[]
    associate: ApplicantColumns[]
  } = {
    default: [
      'type',
      'name',
      'profile-status',
      'issues',
      'risk-level',
      'risk-fraud',
      'risk-aml',
      'created',
      'case-opened',
      'last-updated',
      'recipe',
      'monitoring',
      'assignee',
      'extra-actions',
    ],
    onboarding: [
      'type',
      'name',
      'profile-status',
      'issues',
      'risk-level',
      'created',
      'last-updated',
      'recipe',
      'assignee',
      'extra-actions',
    ],
    monitoring: [
      'type',
      'name',
      'profile-status',
      'issues',
      'risk-level',
      'created',
      'last-updated',
      'recipe',
      'assignee',
      'extra-actions',
    ],
    transactions: [
      'name',
      'profile-status',
      'issues',
      'risk-level',
      'risk-fraud',
      'risk-aml',
      'case-opened',
      'last-updated',
      'extra-actions',
    ],
    associate: ['type', 'name', 'profile-status'],
  }
  return visibleColumns[stage] || visibleColumns['default']
}

export const getApplicantListColumnName = (key: string): string => {
  const columnNames: {
    type: string
    name
    'profile-status': string
    issues: string
    'risk-level': string
    'risk-fraud': string
    'risk-aml': string
    created: string
    'case-opened': string
    'last-updated': string
    recipe: string
    monitoring: string
    assignee: string
  } = {
    type: 'Type',
    name: 'Name',
    'profile-status': 'Profile Status',
    issues: 'Issues',
    'risk-level': 'Risk Level',
    'risk-fraud': 'Fraud Risk',
    'risk-aml': 'AML Risk',
    created: 'Created',
    'case-opened': 'Case Opened',
    'last-updated': 'Last Updated',
    recipe: 'Recipe',
    monitoring: 'Monitoring',
    assignee: 'Assignee',
  }
  return columnNames[key] || ''
}
