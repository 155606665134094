import { mkMutationName } from '@/store/helper'
import { getAuditReportList } from '@/api/auditReport'
export const setAuditLog = mkMutationName('set', 'auditlog', 'log')
export const clearCacheAUDIT = mkMutationName('clear', 'auditlog', 'logs')

export default {
  state: {
    logs: null,
  },
  mutations: {
    [setAuditLog](state, data) {
      state.logs = data
    },
    [clearCacheAUDIT](state, data) {
      state.logs = null
    },
  },
  actions: {
    async getAuditLogs({ commit, getters }, payload) {
      const { entityId, ABORT_CONTROL } = Object.assign(
        { ABORT_CONTROL: {} },
        payload,
      )

      if (getters.isAuditFetchedData || !entityId) return
      const promise = getAuditReportList(entityId, { ABORT_CONTROL })
        .then(data => commit(setAuditLog, data))
        .catch(err => {
          commit(setAuditLog, null)
          throw err
        })
      commit(setAuditLog, promise)
      return promise
    },
  },
  getters: {
    isAuditFetchedData: state => !(state.logs === null),
    isAuditLoading: state => {
      return state.logs !== null && !!state.logs.then
    },
    auditLogsResolve: state => {
      if (!state.logs || state.logs.then) return null
      else return state.logs
    },
  },
}
