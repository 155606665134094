<template lang="pug">
   .forbidden-modal( data-qa="insufficient-access-modal" )
        .image
            img( :src="src" )
        .message
            h2 Insufficient access.
            p Sorry, but you do not have permission to do that.
            p If you feel you should have access to this, <br>please contact your security administrator to request access.
            p Thank you.
        .close.clickable( @click="$emit('close')" )
            simple-icon( icon="close" size="24px" overrideColor="grey")
</template>

<style lang="sass" scoped>
$total-width: 940px
$padding: 50px
$image-width: 320px
$message-width: 550px

.close
    $gap: 28px
    position: absolute
    top: $gap
    right: $gap
.forbidden-modal
    height: 100%
    display: flex
    padding: 0 50px
img
    margin-top: 64px
    height: 300px
    width: 100%
    float: left
    object-fit: contain

.image
    width: $image-width
    flex: 0 0 auto
    height: 100%
.message
    $offset: $total-width - 2*$padding - $message-width - $image-width
    margin-left: $offset
    width: $message-width
    flex: 0 0 auto
    padding-top: 120px
h2
    font-size: $LENGTH__TITLE_SIZE
    margin-bottom: $LENGTH__FONT_SIZE
    font-weight: bold
p
    line-height: 1.6
    font-weight: normal
    font-size: $LENGTH__HEADING_SIZE
</style>

<script>
export default {
  computed: {
    src() {
      return require('@/assets/png/block.svg')
    },
  },
}
</script>
