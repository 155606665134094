import { getOwnershipDetails } from '@/api/kyb'
import { mkMutationName } from '@/store/helper'

export const setOwnershipData = mkMutationName(
  'set',
  'business',
  'ownershipData',
)
export const clearOwnershipData = mkMutationName(
  'clear',
  'business',
  'ownershipData',
)
export const setRanOwnershipQuery = mkMutationName(
  'set',
  'business',
  'ranOwnershipQuery',
)

export default {
  state: {
    ownershipData: null,
    ranOwnershipQuery: false,
  },
  mutations: {
    [setOwnershipData](state, data) {
      state.ownershipData = data
    },
    [clearOwnershipData](state, data) {
      state.ownershipData = null
    },
    [setRanOwnershipQuery](state, data) {
      state.ranOwnershipQuery = data
    },
  },
  actions: {
    async getBusinessOwnershipDetails({ commit, getters }, payload) {
      const { entityId, ABORT_CONTROL } = Object.assign(
        { ABORT_CONTROL: {} },
        payload,
      )

      if (getters.hasOwnerShipData || !entityId) return
      commit(setRanOwnershipQuery, true)
      const promise = getOwnershipDetails(entityId, { ABORT_CONTROL })
        .then(res => {
          const data = { ...res }
          commit(setOwnershipData, data)
        })
        .catch(err => {
          commit(setOwnershipData, null)
          if (err.status === 404) {
            commit(setRanOwnershipQuery, false)
          }
          throw err
        })
      commit(setOwnershipData, promise)
      return promise
    },
    clearOwnershipData({ commit }) {
      commit(clearOwnershipData)
    },
  },
  getters: {
    hasOwnerShipData: state => state.ownershipData !== null,
    isLoading: state => {
      return state.ownershipData !== null && !!state.ownershipData.then
    },
    ownerShipDataResolve: state => {
      if (!state.ownershipData || state.ownershipData.then) return null
      else return state.ownershipData
    },
    ranOwnershipQuery: state => {
      return state.ranOwnershipQuery
    },
  },
}
