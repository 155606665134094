import idNumber from './fields/idNumberField'
import region from './fields/regionField'
import TextInput from '@/components/inputs/TextInput'
import {
  DISPLAY_DATE_FORMAT,
  DISPLAY_MONTH_FORMAT,
  DISPLAY_YEAR_FORMAT,
} from '@/constants.js'
import dateField from './fields/dateField'
import fullNameField from './fields/fullNameField'
import mergeBlueprint from '@/utils/mergeBlueprint.js'
import common from '@frankieone/shared'
import { formatDate } from '@/components/builders/documentForm/DocumentViewTranform.ts'
const group1 = ['ACT'] //idNumber, marriage_date, certificate_date_of_print, certificate_number(optional)
const group2 = ['NT'] //idNumber, marriage_date, certificate_date_of_print, certificate_number
const group3 = ['SA'] //idNumber, marriage_date, certificate_date_of_print(optional), certificate_number(optional)
const group4 = ['NSW', 'TAS', 'VIC', 'WA'] //idNumber, marriage_date, registration_year
const group5 = ['QLD'] //idNumber(optional), marriage_date, certificate_date_of_registration

const stateShowDateOfPrint = [...group1, ...group2, ...group3]
const stateShowCertNum = [...group1, ...group2, ...group3]
const { validator } = common
let blueprint = {
  fields: {
    region: {
      ...region,
      props() {
        const inherited = region.props.bind(this)()
        return {
          ...inherited,
          placeholder: 'Select state or territory',
          label: 'State or territory of registration',
          qa: 'region',
        }
      },
      visibility() {
        return true
      },
      view() {
        return {
          title: 'Registration State',
          value: this.state,
        }
      },
    },
    idNumber: {
      ...idNumber({
        label: 'Registration Number',
        placeholder: 'Registration Number',
      }),
    },
    'extraData.registration_year': {
      ...dateField,

      visibility() {
        return this.value.region && group4.includes(this.value.region)
      },
      props() {
        const inherited = dateField.props.bind(this)()
        return {
          ...inherited,
          label: 'Registration Year',
          minimumView: 'year',
          placeholder: DISPLAY_YEAR_FORMAT,
          qa: 'regYear',
        }
      },
      width() {
        return '25%'
      },
      view() {
        return {
          title: 'Registration Year',
          value: formatDate(this.fieldValue, DISPLAY_YEAR_FORMAT),
        }
      },
    },
    'extraData.marriage_date': {
      ...dateField,
      props() {
        const inherited = dateField.props.bind(this)()
        return {
          ...inherited,
          label: 'Marriage Date',
          qa: 'marriageDate',
        }
      },

      visibility() {
        return true
      },
      width() {
        return '25%'
      },
      view() {
        return {
          title: 'Marriage Date',
          value: formatDate(this.fieldValue, DISPLAY_DATE_FORMAT),
        }
      },
    },

    'extraData.certificate_date_of_registration': {
      ...dateField,
      visibility() {
        return group5.includes(this.value.region)
      },
      props() {
        const inherited = dateField.props.bind(this)()
        return {
          ...inherited,
          label: 'Registration Date',
          qa: 'regDate',
        }
      },
      view() {
        return {
          title: 'Registration Date',
          value: formatDate(this.fieldValue, DISPLAY_DATE_FORMAT),
        }
      },
    },
    ...fullNameField({
      givenNamePath: 'bride_name_first_given',
      familyNamePath: 'bride_name_family',
      otherNamePath: 'bride_name_other_given',
      label: 'Partner One (Brides) Name',
      fieldName: 'brideName',
    }),
    ...fullNameField({
      givenNamePath: 'groom_name_first_given',
      familyNamePath: 'groom_name_family',
      otherNamePath: 'groom_name_other_given',
      label: 'Partner Two (Grooms) Name',
      fieldName: 'groomName',
    }),
    'extraData.certificate_number': {
      component: TextInput,
      props() {
        return {
          placeholder: 'Certificate Number',
          label: 'Certificate Number',
          qa: 'certificateNum',
        }
      },
      visibility() {
        return (
          stateShowCertNum.includes(this.value.region) &&
          this.value.country === 'AUS'
        )
      },
      view() {
        return {
          title: 'Certificate Number',
          value: this.fieldValue,
        }
      },
    },
    'extraData.certificate_date_of_print': {
      ...dateField,
      props() {
        return {
          serialize: true,
          label: 'Date of Print',
          qa: 'dateOfPrint',
          notShowDatePicker: true,
        }
      },

      visibility() {
        return stateShowDateOfPrint.includes(this.value.region)
      },
      width() {
        return '25%'
      },
    },
  },
}
blueprint = mergeBlueprint('MARRIAGE', blueprint)
export { blueprint }
