import { getPageData } from '@/utils/pageDataStorage'
import moment from 'moment'
import { mkDataUrl } from '@/utils/readBase64Document'

export const FACIAL_DUPLICATES_PERMISSIONS = {
  FETCH: 'ffportal_applicant_fetch_facial_duplicates_report.function',
  UPDATE: 'ffportal_applicant_update_facial_duplicates_report.function',
}

export const hasFacialDuplicatesFeature = (): boolean => {
  return !!getPageData()?.features?.facialDuplicatesEnabled
}

export const hasFacialDuplicatesReportFetchPermission = (): boolean => {
  return !!getPageData()?.permissions?.includes(
    FACIAL_DUPLICATES_PERMISSIONS.FETCH,
  )
}

export const hasFacialDuplicatesReportManualOverridePermission =
  (): boolean => {
    return !!getPageData()?.permissions?.includes(
      FACIAL_DUPLICATES_PERMISSIONS.UPDATE,
    )
  }

export const removeFacialDuplicatesRolePermissions = (permissions): boolean => {
  return permissions?.ocr_biometry?.listAction?.filter(
    ({ action_route }) =>
      action_route !== FACIAL_DUPLICATES_PERMISSIONS.FETCH &&
      action_route !== FACIAL_DUPLICATES_PERMISSIONS.UPDATE,
  )
}

export const rankRiskScore = (score: number): string => {
  switch (true) {
    case score >= 90:
      return 'risk_high'
    case score > 0 && score < 90:
      return 'risk_medium'
  }
  return 'risk_low'
}

export const statusMessageByCount = (count: number): string => {
  if (count === 1) {
    return `${count} possible duplicate has been detected`
  }
  return `${count} possible duplicates have been detected`
}

// Checking if valid and not empty string then display the content as a string or "-"
export const assertContent = (content: string | number): string => {
  return content && `${content}`.trim() ? `${content}` : '-'
}

export const parseDate = ({
  date,
  from,
  to,
}: {
  date: string
  from?: string
  to: string
}): string => {
  if (from) {
    return moment(date, from, true).isValid()
      ? moment(date, from).format(to)
      : ''
  }
  return moment(date).isValid() ? moment(date).format(to) : ''
}

export const parseName = (name: {
  givenName?: string
  middleName?: string
  familyName?: string
}): string => {
  const givenName = capitalise(name?.givenName) || ''
  const middleName = capitalise(name?.middleName) || ''
  const familyName = capitalise(name?.familyName) || ''

  return [givenName, middleName, familyName].filter(Boolean).join(' ')
}

export const capitalise = (content: string): string => {
  return content
    ? content
        ?.split(' ')
        ?.map(
          ([first, ...rest]) =>
            first?.toUpperCase() + rest?.join('')?.toLowerCase(),
        )
        ?.join(' ')
    : ''
}

export const makeDataUrl = (mimeType, file) => {
  const isBase64WithPrefix = /base64/.test(file)
  if (isBase64WithPrefix) {
    return file
  } else {
    return mkDataUrl(mimeType, file)
  }
}
