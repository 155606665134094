<template lang="pug">
  #portal-frame
    portal-header
    slot

</template>
<script>
import PortalHeader from '@/frames/portal-frame/PortalHeader'
export default {
  components: {
    PortalHeader,
  },
}
</script>
