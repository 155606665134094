export default function mapBackendAlert(backendTerm) {
  if (typeof backendTerm !== 'string') {
    return backendTerm
  }

  let term = backendTerm.toLowerCase(),
    type
  const extra = {}

  switch (term) {
    case 'partial':
    case 'p':
    case 'ptl':
      term = 'partial'
      break
    case 'media':
    case 'm':
      term = 'media'
      break
    case 'watchlist':
    case 'watchlists':
    case 'wl':
      term = 'watchlist'
      break
    case 'sanctions':
    case 'sanction':
    case 's':
      term = 'sanction'
      break
    case 'blacklist':
    case 'bl':
      term = 'blacklist'
      break
    case 'duplicate':
    case 'dup':
      term = 'duplicate'
      break
    case 'risk':
    case 'rsk':
      term = 'rsk'
      break
  }

  // categorize terms
  if (['media', 'm'].includes(term)) type = 'warning'

  if (
    [
      '404',
      '500',
      'sanctions',
      's',
      'sanction',
      'error',
      '404',
      '500',
      'ptl',
      'watchlist',
      'wl',
      'pep',
      'partial',
      'kyc',
      'att-pht',
      'att-idv',
    ].includes(term)
  )
    type = 'alert'

  if (['duplicate', 'blacklist', 'bl', 'dup'].includes(term)) {
    type = 'action'

    switch (term) {
      case 'duplicate':
      case 'dup':
        extra.label = 'Duplicate'
        extra.action = 'duplicate'
        break
      case 'blacklist':
      case 'bl':
        extra.label = 'Blacklist'
        extra.action = 'blacklist'
        break
    }
  }

  if (['clear'].includes(term)) type = 'success'

  for (let i = 1; i < 5; i++) {
    if ([`pepclass${i}`, `pep-class-${i}`].includes(term))
      term = `pep-class-${i}`
  }
  /// make term variations consistent

  return {
    term,
    type,
    extra,
  }
}

export function blacklistAndDuplicateDiscovery(details) {
  const getContent = item => {
    let value = null
    if (item) {
      if (item.length) {
        if (item[0].pro) {
          if (item[0].pro.resultState) value = item[0].pro.resultState
        }
      }
    }
    return value
  }
  const isClear = value => value === 'success_clear' || value === 'no_match'
  const amlList = []
  const blacklist = (
    getContent(details.checkResultsListBlacklist) || 'NO_MATCH'
  ).toLowerCase()
  const duplicate = (
    getContent(details.checkResultsListDuplicate) || 'NO_MATCH'
  ).toLowerCase()

  if (!isClear(blacklist)) amlList.push('blacklist')
  if (!isClear(duplicate)) amlList.push('duplicate')

  return amlList
}
export function mapAlertList(list, options = {}) {
  const { stringify, injectClear } = Object.assign(
    {
      stringify: false,
      injectClear: false,
    },
    options,
  )

  if (!list || list.length == 0) list = injectClear ? 'clear' : []
  let asArray
  // list can be string or array, so make it always an array
  if (!Array.isArray(list)) asArray = list.split(',')
  else asArray = list

  let alertList = asArray
    .map(el => el.trim())
    .filter(Boolean)
    .map(mapBackendAlert)
    .sort(sorter)

  if (stringify) alertList = alertList.map(el => el.term)

  return alertList
}
export function sorter(a, b) {
  const getPoints = alert => {
    const index = [
      'blacklist',
      'duplicate',
      '404',
      '500',
      'sanction',
      'partial',
      'pep',
      'pep-class-1',
      'pep-class-2',
      'pep-class-3',
      'pep-class-4',
      'pep-class-5',
      'watchlist',
      'media',
    ].indexOf(alert.term)

    return index === -1 ? Infinity : index
  }

  return getPoints(a) - getPoints(b)
}
