<template lang="pug">
    label-wrapper.list-pick-input( :label='label' :class="[{'is-invalid': !validation, 'show-dropdown-only': showDropdownOnly, disabled }]" :displayV2="displayV2")
        v-select.content__centered.basic-input-style( ref="input"
            item-text="label"
            item-value="value"
            :class="[{'is-invalid': !validation}]"
            :error="!validation"
            :value="value"
            :items="options"
            :multiple="multiple"
            :placeholder="placeholder"
            :return-object="emitFullOption"
            :data-qa='qa'
            :id='id'
            solo
            flat
            dense
            :attach='attach'
            :menu-props="{zIndex: 10000, ...menuProps}"
            :disabled='disabled'
            @input="$emit('input', $event)"
        )
            template( v-if="multiple" v-slot:prepend-item="")
                .clear-all-item( @click="clearAll" ) {{ clearText }}
            template( v-if="$scopedSlots.default || multiple" v-slot:item="{ item }" )
                slot( v-if="$scopedSlots.default" :option="item" )
                template( v-else-if="multiple")
                    .multiple-item( :style="{ 'width': '100%', 'display': 'flex', 'align-items': 'center', 'justify-content': 'space-between' }" )
                        span( :style="{'color': isSelected(item.value) ? $style.COLOR__BLUE : $style.COLOR__DARK }") {{item.label}}
                        simple-icon.icon( icon="check-mark" padding="3px" :overrideColor="isSelected(item.value) ? $style.COLOR__BLUE : $style.COLOR__DARK" )
            template( v-if="$scopedSlots.selectedLabel || $scopedSlots.default" v-slot:selection="{ item }"  )
                slot( name="selectedLabel" :option="item" )
                    slot( :option="item")
            template(v-slot:append)
                simple-icon.arrow(icon="arrow-toggle" :overrideColor='COLOR__DARK_NAVY_60' padding='0' size="14px" )

</template>

<script>
import LabelWrapper from '@/components/wrappers/LabelMessageWrapper.vue'
import { COLOR__DARK_NAVY_60 } from '@/styles/config2.sass'
export default {
  constants: {
    COLOR__DARK_NAVY_60: COLOR__DARK_NAVY_60,
  },
  components: {
    LabelWrapper,
  },
  props: {
    qa: String,
    menuProps: {
      type: Object,
      default: () => {},
    },
    validation: {
      type: [Boolean, String],
      default: true,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: ' ',
    },
    value: {
      require: true,
    },
    options: {
      required: true,
      type: Array,
    },
    clearText: {
      type: String,
      default: 'All',
    },
    multiple: Boolean,
    fullHeight: {
      type: Boolean,
      default: false,
    },
    emitFullOption: Boolean,
    showDropdownOnly: Boolean,
    keepOpen: Boolean,
    id: String,
    disabled: Boolean,
    displayV2: Boolean,
    attach: [String, HTMLDivElement],
  },
  methods: {
    clearAll() {
      const clearValue = this.multiple ? [] : ''
      this.$emit('input', clearValue)
    },
    setIsToggled(value) {
      const ref = this.$refs['input']
      if (ref) {
        ref.isMenuActive = this.keepOpen || value
      }
    },
    getIsToggled() {
      const ref = this.$refs['input']
      return ref && ref.isMenuActive
    },
    isSelected(value) {
      if (this.multiple) return this.value.includes(value)
      else return this.value === value
    },
  },
  mounted() {
    if (this.keepOpen) this.setIsToggled(true)
  },
}
</script>
<style lang="sass" scoped>
$item-height: 40px
.disabled
    cursor: not-allowed
    &::v-deep
        &.disabled
            & .v-input, & .v-input__slot, & input
                background-color:  $COLOR__BACKGROUND_GREY!important
                cursor: not-allowed
                color: $COLOR__DARK_NAVY_40!important
        &.disabled
            & .v-input__slot input, & .v-select__selection
                color: $COLOR__DARK_NAVY_40!important
.clear-all-item
    cursor: pointer
    text-align: center
    line-height: $item-height
    border-bottom: 1px solid $COLOR__GREY
    &:hover
        color: $COLOR__BLUE
    // background-color: $COLOR__LIGHTGREY

.list-pick-input::v-deep
    .v-menu__content
        @extend %custom-list-pick-input
        width: calc(100% - 20px)
        top: 64px !important
    .v-input
        font-size: 14px !important
        line-height: 150%!important
        color: $COLOR__DARK_NAVY!important
    .v-input__control
        min-height: 30px!important
    &.show-dropdown-only
        visibility: hidden
    .basic-input-style
        // padding: 0 12px !important
        // margin: 0 !important
    .content__centered
      align-items: center
    .v-input__slot
        margin: 0 !important
        &::before
            display: none !important
        &::after
            display: none !important
    .v-text-field__details
        display: none !important
    .v-list-item
        min-height: initial !important
        height: $item-height!important
    .v-list-item__title
        font-size: 14px!important
    &.is-invalid
        color: $COLOR__ACCENT !important
        input
            color: $COLOR__ACCENT !important
</style>
