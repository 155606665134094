export default function normalizeItemsArray(itemsArrayOrLeaf) {
  const tickValueFinder = item => item.value
  const isArray = Array.isArray(itemsArrayOrLeaf)
  if (isArray) {
    itemsArrayOrLeaf = itemsArrayOrLeaf.map(item => {
      item.tick = tickValueFinder(item)
      if (Array.isArray(item.items))
        item.items = normalizeItemsArray(item.items)

      return item
    })
  }

  return itemsArrayOrLeaf
}
