<script>
export default {
  props: {
    issues: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    hasIssues(field) {
      return this.issues.includes(field)
    },
    setIssues(issues, prefix = '') {
      issues = issues.map(issue => prefix + issue)
      this.$emit('update:issues', issues)
    },
    replaceIssues(issues, prefix) {
      const allIssues = this.issues
      const filtered = allIssues.filter(issue => issue.indexOf(prefix) !== 0)
      const prefixed = issues.map(issue => `${prefix}${issue}`)
      const merged = [...filtered, ...prefixed]
      this.$emit('update:issues', merged)
    },
    getIssues() {
      // you can specify other source of issues as the first argument, but have to add prefix as second argument

      const [fst, snd] = arguments
      let prefix
      // let issues;

      if (snd) {
        prefix = fst
        // issues = snd;
      } else {
        // issues = this.issues;
        prefix = fst
      }
      const filtered = this.issues.filter(field => field.indexOf(prefix) === 0)
      const cleaned = filtered.map(field => field.replace(prefix, ''))

      return cleaned
    },
    getHasIssuesProps(field) {
      //helper
      return {
        value: this.value.idNumber,
        validation: !this.hasIssues(field),
      }
    },
    getHasIssuesEvents(field) {
      //helper
      return {
        input: $event => this.setField(field, $event),
      }
    },
  },
}
</script>
