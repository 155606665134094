/* eslint-disable */
import query from 'query-string'

export default function mapRouteToUrl(route, extra: any = {}) {
  let url

  const isDev = process.env.NODE_ENV === 'development'
  const isTest = extra.isTest
  const baseDomain = process.env.VUE_APP_SERVER_API || 'http://localhost:8000'
  const originURL = location.origin
  const makeQueryString = object =>
    query.stringify(object, { arrayFormat: 'bracket' })
  const baseDataUrl = (
    options: { endpoint?: string; version?: string } = {},
  ) => {
    let { endpoint, version, isPublic } = Object.assign(
      {
        version: 'v1',
        endpoint: 'data',
        isPublic: false,
      },
      options ?? {},
    )
    if (isPublic) endpoint = 'auth'

    return `${baseDomain}/${endpoint}/${version}`
  }
  // pages
  switch (route) {
    case 'ffportal_login':
      url = '/login'
      break

    case 'ffportal_entity_duplicate':
      const isConfirmed = extra.isConfirmed
      const prefixURL = isConfirmed ? 'confirmed' : 'resolve'
      const type = extra.isBlacklist
        ? `${prefixURL}-blacklist`
        : `${prefixURL}-duplicate`

      url = `/onboarding/profile/${extra.entityId}/${type}`
      break
    case 'ffportal_onboarding':
      url = '/onboarding'
      break
    case 'ffportal_monitoring':
      url = '/monitoring'
      break
    case 'ffportal_transactions':
      url = '/transactions'
      break
    case 'ffportal_blocklist':
      url = '/blocklist'
      break
    case 'ffportal_new_blocklist':
      url = '/blocklist/new'
      break
    case 'ffportal_pep':
      url = `/onboarding/profile/${extra.entityId}/pep-sanctions`
      break
    case 'ffportal_biometry':
      url = `/onboarding/profile/${extra.entityId}/biometry-ocr`
      break
    case 'ffportal_transactions_table':
      url = `/transactions/profile/${extra.entityId}/transactions`
      break
    case 'ffportal_characteristics_table':
      url = `/transactions/profile/${extra.entityId}/characteristics`
      break
    case 'ffportal_document_upload':
      url = `/onboarding/profile/${extra.entityId}/supporting-documents`
      break
    case 'ffportal_applicant_detail': {
      extra.tab = extra.tab || 'general-information'
      let prefix = extra.from ? `/${extra.from}` : ''
      url = `${prefix}/profile/${extra.entityId}/${extra.tab}`
      break
    }
    case 'ffportal_applicant_midv_index': {
      let prefix = extra.from ? `/${extra.from}` : ''
      url = `${prefix}/profile/${extra.entityId}/manual-idv`

      break
    }
    case 'ffportal_applicant_new':
      url = '/new-profile'
      break
    case 'ffportal_users_index':
      url = '/admin/users'
      break
    case 'ffportal_personal_settings_index':
      url = '/admin/personal'
      break
    case 'ffportal_user_edit':
      url = `/admin/users/${extra}/edit`
      break
    case 'ffportal_user_new':
      url = '/admin/users/new'
      break
    case 'ffportal_role_user_permission_list':
      url = `/admin/users/${extra.userId}/permissions`
      break
    case 'ffportal_role_index':
      url = '/admin/roles'
      break
    case 'ffportal_settings_index':
      url = '/admin/settings'
      break
    case 'ffportal_settings_mfa':
        url = '/admin/personal'
        break
    case 'ffportal_permission_mapping_page':
      url = '/admin/roles/' + extra.roleId + '/permissions'
      break
    case 'ffportal_role_edit':
      url = '/admin/roles/' + extra.roleId + '/edit'
      break
    case 'ffportal_role_view':
      url = '/admin/roles/' + extra.roleId + '/view'
      break
    case 'ffportal_role_new':
      url = '/admin/roles/new'
      break
    case 'ffportal_dashboard':
      url = '/dashboard/'
      break
    case 'ffportal_system_monitoring':
      url = '/dashboard/system-monitoring'
      break
    case 'ffportal_reset_password_request':
      url = '/reset-password/' + extra.username
      break
  }

  // DATA ENTPOINTS
  switch (route) {
    // SSO
    case 'bff_sso_login':
      {
        url =
          baseDataUrl({
            endpoint: 'sso',
          }) + '/login'
      }
      break
    //SESSIONS
    case 'bff_logout':
      {
        const baseUrl = baseDataUrl({
          endpoint: 'auth',
          version: 'v1',
        })
        const builder = new URL(`${baseUrl}/session/logout`)
        builder.searchParams.set('token', extra.token)
        builder.searchParams.set('returnTo', window.location.origin)
        url = builder.href
      }
      break
    case 'ffportal_reset_password':
      url =
        baseDataUrl({ endpoint: 'auth' }) +
        `/sessions/password-reset/${extra.username}`
      break
    case 'ffportal_json_login':
      url = baseDataUrl({ endpoint: 'auth' }) + '/sessions'
      break
    case 'ffportal_user_child_view':
      url = baseDataUrl({}) + '/organization/child-view'
      break
    case 'ffportal_address_autocomplete':
      url = baseDataUrl({}) + '/address/search'
      break
    case 'ffportal_address_detail':
      url = baseDataUrl({}) + '/address/' + extra.id
      break
    case 'ffportal_generate_report':
      url =
        baseDataUrl({ version: 'v2' }) + `/business/report?level=${extra.level}`
      break
    case 'ffportal_business_ownership_details':
      url =
        baseDataUrl({ version: 'v2' }) + `/business/${extra.entityId}/ownership`
      break
    case 'ffportal_credit_header_acknowledgement':
      url =
        baseDataUrl({}) + `/applicants/${extra}/check-header-acknowledgement`
      break
    case 'ffportal_applicants_list': {
      const { stage = 'onboarding', limit = '50', ...extraQuery } = extra
      const queryString = query.stringify({
        ...extraQuery,
        stage,
        limit,
      })
      url = baseDataUrl({}) + `/applicants?${queryString}`
      break
    }
    case 'abr_search': {
      const queryString = query.stringify({ search: extra.search })
      url = baseDataUrl({ version: 'v2' }) + `/businesses?${queryString}`
      break
    }
    case 'ffportal_mark_entity_blacklist': {
      url = baseDataUrl() + `/applicants/${extra.entityId}/setBlacklist`
      break
    }
    case 'ffportal_get_all_blocklist': {
      url = baseDataUrl() + `/blocklists`
      break
    }
    case 'ffportal_applicant_detail.function':
      url = baseDataUrl({}) + `/applicants/${extra.entityId}`
      break
    case 'ffportal_applicant_new_submit':
      url = baseDataUrl({}) + `/applicants?saveOnly=${extra.saveOnly}`
      break
    case 'ffportal_upload_file':
      url = baseDataUrl({}) + '/file'
      break
    case 'ffportal_get_scan': {
      const queryString = query.stringify({
        side: extra.side,
        type: extra.type,
      })
      url =
        baseDataUrl() +
        `/documents/${extra.documentId}/scans/${extra.scanId}?${queryString}`
      break
    }
    case 'ffportal_applicant_midv_update':
      url =
        baseDataUrl({ version: extra.version }) +
        `/applicants/${extra.entityId}?saveOnly=${extra.saveOnly}&noNameDobValidation=${extra.noNameDobValidation}&noInvalidate=${extra.noInvalidate}`
      break
    case 'ffportal_submit_new_blocklist':
      url = baseDataUrl({}) + `/blocklists`
      break
    case 'ffportal_applicant_check_summary.function':
      url = baseDataUrl({}) + `/applicants/${extra.entityId}/checks`
      break
    case 'ffportal_applicant_override_check_summary.function':
      url =
        baseDataUrl({}) +
        `/applicants/${extra.entityId}/overrideChecks?checkField=${extra.checkField}`
      break
    case 'ffportal_applicant_aml':
      url = baseDataUrl({}) + `/applicants/${extra.entityId}/checks/aml/matches`
      break
    case 'ffportal_applicant_fraud':
      url = baseDataUrl() + `/applicants/${extra.entityId}/checks/fraud`
      break
    case 'ffportal_applicant_fraud_override_status':
      url = baseDataUrl() + `/applicants/${extra.entityId}/checks/fraud/matches`
      break
    case 'ffportal_admin_settings':
      url =
        baseDataUrl({
          endpoint: 'admin',
          version: '',
        }) + `settings`
      break
    case 'ffportal_applicant_aml_detail':
      url =
        baseDataUrl({}) +
        `/applicants/${extra.entityId}/checks/aml/matches/${extra.amlGroupId}`
      break
    case 'ffportal_entity_aml_bulk_status':
      url =
        baseDataUrl({}) +
        `/applicants/${extra.entityId}/checks/aml/${extra.checkId}`
      break
    case 'ffportal_entity_risk_assessment_get.function':
      url = baseDataUrl({}) + `/applicants/${extra.entityId}/checks/risk`
      break
    case 'ffportal_entity_risk_level_update':
      url = baseDataUrl({}) + `/applicants/${extra.entityId}/checks/risk/level`
      break
    case 'ffportal_audit_list':
      url = baseDataUrl({}) + `/audit-logs`
      break
    case 'ffportal_applicant_ocr_report_manual_override.function':
      url = baseDataUrl({}) + `/applicants/${extra.entityId}/checks/ocr/report`
      break
    case 'ffportal_applicant_idv_result_manual_override.function':
      url = baseDataUrl({}) + `/applicants/${extra.entityId}/checks/idv`
      break
    case 'ffportal_applicant_ocr_retrigger_idv':
      url =
        baseDataUrl({}) + `/external-idv/applicant/${extra.entityId}/retrigger`
      break
    case 'ffportal_applicant_ocr_refresh_idv':
      url =
        baseDataUrl({
          version: 'v2',
          endpoint: 'data',
        }) + `/idvCheck/${extra.entityId}/restart`
      break
    case 'ffportal_applicant_ocr_document_data_manual_override.function':
      url =
        baseDataUrl({}) + `/applicants/${extra.entityId}/checks/ocr/document`
      break
    case 'ffportal_applicant_biometry_report.function':
      url = baseDataUrl({}) + `/applicants/${extra.entityId}/checks/biometrics/`
      break
    case 'ffportal_applicant_biometry_report_manual_override.function':
      url =
        baseDataUrl({}) + `/applicants/${extra.entityId}/checks/biometry/report`
      break
    case 'ffportal_applicant_fetch_facial_duplicates_report.function':
      url =
        baseDataUrl({}) +
        `/applicants/${extra.entityId}/checks/biometrics/facialduplicates`
      break
    case 'ffportal_document_get_scans':
      url =
        baseDataUrl({}) +
        `/documents/${extra.documentId}/scans${
          extra.logOperation
            ? '?logOperation=true&entityId=' + extra.entityId
            : ''
        }`
      break
    case 'ffportal_document_get_scan':
      url =
        baseDataUrl() +
        `/documents/${extra.documentId}/scans/${extra.scanId}?${extra.queryString}`
      break
    case 'ffportal_upload_files':
      url = baseDataUrl({ endpoint: 'file' })
      break
    case 'ffportal_duplicates_list':
      {
        const checkType = extra.isBlacklist ? 'blacklists' : 'duplicates'
        url =
          baseDataUrl({}) + `/applicants/${extra.entityId}/checks/${checkType}`
      }
      break
    case 'ffportal_reporting_dashboard':
      {
        const queryString = makeQueryString(extra)
        const urlBase = baseDataUrl() + '/analytics'
        url = baseDataUrl({}) + '/analytics?' + queryString
      }
      break
    case 'ffportal_reporting_generate_report':
      {
        url = baseDataUrl({}) + '/generate-report'
      }
      break
    case 'ffportal_comment_manual_idv':
      url = baseDataUrl({}) + `/applicants/${extra.entityId}/comments`
      break
    case 'ffportal_entity_state_update':
      url = baseDataUrl() + `/applicants/${extra.entityId}/state`
      break
    case 'ffportal_applicant_monitoring_status_update':
      url = baseDataUrl() + `/applicants/${extra.entityId}/monitoring`
      break
    case 'ffportal_comment_new_profile':
      url = baseDataUrl({}) + '/comment/new_profile'
      break
    case 'ffportal_comment_applicant_detail':
      url = baseDataUrl({}) + `/applicants/${extra.entityId}/comments`
      break
    case 'ffportal_entity_assign_user':
      // const withEntity = extra.entityId ? `/applicants/${extra.entityId}/assign`
      url = baseDataUrl({}) + `/applicants/${extra.entityId}/assign`
      break
    //ADMIN ENPOINT

    case 'ffportal_user_lock':
      url = baseDataUrl() + `/users/${extra.userId}/lock`
      break

    case 'ffportal_user_delete':
      url = baseDataUrl() + `/users/${extra.userId}`
      break

    case 'ffportal_role_list.function':
      url = baseDataUrl() + `/roles`
      break
    case 'ffportal_role_detail.function':
      url = baseDataUrl() + `/roles/${extra.roleId}`
      break
    case 'ffportal_role_update.function':
      url = baseDataUrl() + `/roles/${extra.roleId}`
      break
    case 'ffportal_entity_flag_watchlist':
      url = baseDataUrl({}) + `/applicants/${extra.entityId}/checks/blacklists`
      break
    case 'ffportal_role_delete.function':
      url = baseDataUrl() + `/roles/${extra.roleId}`
      break

    case 'ffportal_role_create.function':
      url = baseDataUrl() + `/roles`
      break

    case 'ffportal_role_permission_mapping.function':
      url = baseDataUrl() + `/roles/${extra.roleId}/permissions`
      break

    case 'ffportal_user_detail':
      url = baseDataUrl() + `/users/${extra.userId}`
      break
    case 'ffportal_user_list':
      url = baseDataUrl() + `/users`
      break
    case 'ffportal_user_create':
      url = baseDataUrl() + `/users`
      break
    case 'ffportal_user_edit.function':
      url = baseDataUrl() + `/users/${extra.userId}`
      break
    case 'ffportal_entity_flag_duplicate':
      url =
        baseDataUrl({}) +
        `/applicants/${extra.entityId}/checks/duplicates/${extra.otherEntityId}`
      break
    case 'ffportal_entity_flag_blacklist':
      url = baseDataUrl({}) + `/applicants/${extra.entityId}/checks/blacklists`
      break

    case 'ffportal_role_delete':
      url = (isDev ? baseDomain : '') + '/role/delete/' + extra
      break
    case 'ffportal_actions_list':
      url = baseDataUrl({}) + `/action/list`
      break

    case 'ffportal_new_password_form':
      url = originURL + '/new-password'
      break
    case 'ffportal_document_delete':
      const { documentId, entityId, docType } = extra
      url = baseDataUrl({}) + `/applicants/${entityId}/documents/${documentId}`
      break
  }
  //DOWNLOAD
  switch (route) {
    case 'ffportal_audit_download_pdf':
      url = baseDataUrl({}) + `/audit-logs.pdf`
      break
    case 'ffportal_reporting_download':
      const filters = makeQueryString(extra.filters)
      url = baseDataUrl({}) + `/analytics.csv?` + filters
      break
    case 'ffportal_kyc_generate_pdf':
      url = baseDataUrl({}) + `/kyc/${extra.entityId}/generate-pdf`
      break
  }
  // OLDIES
  switch (route) {
    case 'ffportal_create_new_profile_endpoint':
      url = (isDev ? baseDomain : '') + '/onboarding/applicant/new/submit'
      break
    case 'ffportal_manual_idv_endpoint':
      url = (isDev ? baseDomain : '') + '/onboarding/applicant/midv/' + extra
      break

    case 'ffportal_entity_blacklist_list.function':
      url = (isDev ? baseDomain : '') + `/applicant/${extra.entityId}/blacklist`
      break

    case 'ffportal_monitoring_index_data':
    case 'ffportal_monitoring_case_management_data':
      url = (isDev ? baseDomain : '') + '/monitoring/case-management/data'
      break
    case 'ffportal_logout':
      url = (isDev ? baseDomain : '') + '/logout'
      break

    case 'ffportal_entity_aml_status_update':
      url =
        (isDev ? baseDomain : '') +
        `/entity/${extra.entityId}/${extra.checkId}/${extra.checkClass}/${extra.checkClassId}/status`
      break
    case 'ffportal_role_user':
      url = (isDev ? baseDomain : '') + '/role/' + extra
      break
  }

  return url
}
export function getRouteSidebarLinks(route) {
  let sidebarItems: any[] = []

  const makeIcon = name => ({ name })
  const makeRoute = to => ({ to })
  const makeItem = (label, iconName, to) => ({
    icon: makeIcon(iconName),
    label,
    route: makeRoute(to),
  })

  switch (route) {
    case 'onboarding':
      sidebarItems = [
        makeItem(
          'Case Management',
          'package_org',
          mapRouteToUrl('ffportal_onboarding_index'),
        ),
      ]
      break
    case 'monitoring':
      sidebarItems = [
        makeItem(
          'Case Management',
          'package_org',
          mapRouteToUrl('ffportal_monitoring_index'),
        ),
      ]
      break
  }

  return sidebarItems
}
