import idNumber from './fields/idNumberField'
import common from '@frankieone/shared'
import mergeBlueprint from '@/utils/mergeBlueprint.js'
const { validator } = common
let blueprint = {
  fields: {
    idNumber: {
      ...idNumber({ mask: 'XXXXXXXXX' }),
      view() {
        return {
          title: 'Card Number',
          value: this.fieldValue,
        }
      },
    },
  },
}

blueprint = mergeBlueprint('IMMIGRATION', blueprint)
export { blueprint }
