const mapIf = (data, func) => (data && data.map(func)) || []

export function normalizeItems(items, filterItems) {
  if (filterItems) items = items.filter(filterItems)

  return mapIf(items, ({ key, value, item }) => ({
    key,
    value: value || item,
  }))
}
export function normalizeLists(data) {
  const fields = [
    'sanctions',
    'watchlists',
    'pepsPep',
    'pepsPepClass1',
    'pepsPepClass2',
    'pepsPepClass3',
    'pepsPepClass4',
    'pepsPepClass5',
  ]
  const normalized = {}
  fields.forEach(field => {
    let normalizedField
    switch (field) {
      case 'sanctions':
      case 'watchlists':
      case 'pepsPep':
      case 'pepsPepClass1':
      case 'pepsPepClass2':
      case 'pepsPepClass3':
      case 'pepsPepClass4':
      case 'pepsPepClass5':
        const filterItems = ({ key, value, item }) =>
          key.indexOf('status.') !== 0
        normalizedField = mapIf(data[field], ({ name, items }) => ({
          title: name,
          items: normalizeItems(items, filterItems),
        }))
        break
    }
    normalized[field] = normalizedField
  })
  return normalized
}
