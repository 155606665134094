<script>
export default {
  props: {
    focused: Boolean,
    value: {},
    options: Array,
    emptyValues: {
      type: Array,
      default: () => ['', null, undefined],
    },
  },
  data: () => ({
    cursorIndex_: null,
  }),
  computed: {
    cursorIndex: {
      get() {
        let index
        const cursorDefined = this.cursorIndex_ !== null,
          selectedDefined = this.selectedIndex !== -1
        if (cursorDefined) {
          index = this.cursorIndex_
        } else if (selectedDefined) {
          index = this.selectedIndex
        } else {
          return -1 // initially no option should be highlighted
          // const emptyIndex = this.options.findIndex(opt => this.emptyValues.includes(opt.value));
          // index = emptyIndex !== -1 ? emptyIndex : 0; // start at empty value OR zero as a fallback
        }
        return Math.min(Math.max(index, 0), this.options.length - 1)
      },
      set(index) {
        this.cursorIndex_ = index
        this.focus()
      },
    },
    selectedIndex() {
      let index

      if (this.hasEmptyValue()) return -1
      const findIndex = selected => {
        return this.options.findIndex(opt => {
          let value = selected
          if (typeof selected.value !== 'undefined') value = selected.value

          return value === opt.value
        })
      }
      // if(this.multiple) {
      //     index = (!Array.isArray(this.value) ? [this.value] : this.value).map(findIndex)
      // } else {
      index = findIndex(this.value)
      // }
      return index
    },
  },
  methods: {
    hasEmptyValue() {
      return this.isEmpty(this.value)
    },
    exit() {
      this.$emit('exit')
    },
    focus() {
      const li = this.$el.querySelector(`li:nth-child(${this.cursorIndex + 1})`)
      const container = this.$el

      const scrollTo = top => {
        if (container.scrollTo) container.scrollTo({ top, behavior: 'smooth' })
        else container.scrollTop = top
      }
      if (li) {
        const bottomPos = li.offsetTop + li.offsetHeight
        if (bottomPos > container.scrollTop + container.clientHeight) {
          scrollTo(bottomPos - container.clientHeight)
        } else if (li.offsetTop < container.scrollTop) {
          scrollTo((container.scrollTop = li.offsetTop))
        }
      }
    },
    select() {
      const option = this.options[this.cursorIndex]
      this.$emit('input', option)
      this.$emit('select')
      // this.cursorIndex = null;
    },
    moveUp() {
      this.cursorIndex = this.cursorIndex - 1
    },
    moveDown() {
      this.cursorIndex = this.cursorIndex + 1
    },
    onKeyDown(event) {
      if (!this.focused) return // just making sure

      let action
      switch (event.key) {
        case 'Down': // IE/Edge specific value
        case 'ArrowDown':
          action = this.moveDown
          break
        case 'Up': // IE/Edge specific value
        case 'ArrowUp':
          action = this.moveUp
          break
        // case " ":
        case 'Enter':
          action = this.select
          break
        case 'Esc': // IE/Edge specific value
        case 'Escape':
          action = this.exit
          break
      }
      if (action) {
        event.preventDefault()
        action()
      }
    },
    listenKeys() {
      window.document.addEventListener('keydown', this.onKeyDown)
    },
    removeListeners() {
      window.document.removeEventListener('keydown', this.onKeyDown)
    },
  },
  watch: {
    options() {
      this.cursorIndex = null
    },
    focused() {
      this.cursorIndex_ = null

      if (this.focused) {
        this.listenKeys()
        this.focus()
      } else {
        this.removeListeners()
      }
    },
  },
  destroyed() {
    this.removeListeners()
  },
}
</script>
