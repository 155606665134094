export const DISPLAY_DATE_FORMAT = 'DD-MM-YYYY'
export const DISPLAY_DATE_MONTH_YEAR_FORMAT = 'DD MMMM YYYY'
export const DISPLAY_DATE_SHORT_MONTH_YEAR_FORMAT = 'DD MMM YYYY'
export const DISPLAY_MONTH_FORMAT = 'MM-YYYY'
export const DISPLAY_YEAR_FORMAT = 'YYYY'
export const DISPLAY_TIME_FORMAT = 'hh:mm a'
export const DISPLAY_TIME_SECONDS_FORMAT = 'hh:mm:ss a'
export const PLAIN_DATE_FORMAT = 'DDMMYYYY'
export const EVENT_PROFILE_CREATED = 'profile_created'
export const EVENT_MANUAL_IDV = 'manual_idv_submit'
export const ERROR_MESSAGE = 'There was an issue. Please, try again.'
export const ERROR_MESSAGE_NO_RESULT = 'There is no result'
export const SECURITY_ERROR_MESSAGE =
  "There was a security problem. The requested information doesn't belong to this account. Please, log in with the right account."

export const ADDRESS_TYPE = {
  current: 'RESIDENTIAL1',
  previous: 'RESIDENTIAL2',
  mailing: 'POSTAL',
  business: 'REGISTERED_OFFICE',
  place_of_business: 'PLACE_OF_BUSINESS',
}

export const BUSINESS_TYPE = [
  { value: 'PRV', label: 'Australian Private Company' },
  { value: 'PUB', label: 'Australian Public Company' },
  { value: 'OIE', label: 'Other Incorporated Entity' },
  // { value: "APUB", label: "Australian Public Company" },
  { value: 'APTY', label: 'Australian Proprietary Company' },
  { value: 'FNOS', label: 'Foreign Company (Overseas)' },
  { value: 'NRET', label: 'Non Registered Entity (Nret)' },
  { value: 'RACN', label: 'Registered Australian Body' },
]

export const BLACKLIST_REASON_CODE_OPTIONS = [
  { label: 'Fabricated Identity', value: 'FABRICATED_IDENTITY' },
  { label: 'Identity Takeover', value: 'IDENTITY_TAKEOVER' },
  { label: 'Falsified ID Documents', value: 'FALSIFIED_ID_DOCUMENTS' },
  { label: 'Stolen ID Documents', value: 'STOLEN_ID_DOCUMENTS' },
  { label: 'Merchant Fraud', value: 'MERCHANT_FRAUD' },
  { label: 'Never Pay / Bust Out', value: 'NEVER_PAY_BUST_OUT' },
  { label: 'Conflicting Data Provided', value: 'CONFLICTING_DATA_PROVIDED' },
  { label: 'Money Mule', value: 'MONEY_MULE' },
  { label: 'False Fraud Claim', value: 'FALSE_FRAUD_CLAIM' },
  { label: 'Fraudulent 3rd Party', value: 'FRAUDULENT_3RD_PARTY' },
  { label: 'Company Takeover', value: 'COMPANY_TAKEOVER' },
  { label: 'Fictitious Employer', value: 'FICTITIOUS_EMPLOYER' },
  { label: 'Collusive Employer', value: 'COLLUSIVE_EMPLOYER' },
  { label: 'Over-valuation of Assets', value: 'OVER_VALUATION_OF_ASSETS' },
  {
    label: 'Falsified Employment Details',
    value: 'FALSIFIED_EMPLOYMENT_DETAILS',
  },
  { label: 'Manipulated Identity', value: 'MANIPULATED_IDENTITY' },
  { label: 'Syndicated Fraud', value: 'SYNDICATED_FRAUD' },
  { label: 'Internal Fraud', value: 'INTERNAL_FRAUD' },
  { label: 'Bank Fraud', value: 'BANK_FRAUD' },
  { label: 'Undisclosed Data', value: 'UNDISCLOSED_DATA' },
  { label: 'False Hardship', value: 'FALSE_HARDSHIP' },
  { label: 'SMR report lodged', value: 'SMR_REPORT_LODGED' },
  { label: '2x SMR reports lodged', value: '2X_SMR_REPORTS_LODGED' },
]
export const BLOCKLISTATTRIBUTE = {
  ENTIRE_ENTITY: 'ENTIRE_ENTITY',
  MAILING_ADDRESS: 'MAILING_ADDRESS',
  RESIDENTIAL_ADDRESS: 'RESIDENTIAL_ADDRESS',
  EMAIL_ADDRESS: 'EMAIL_ADDRESS',
  PHONE_NUMBER: 'PHONE_NUMBER',
  ID_DOCUMENT: 'ID_DOCUMENT',
  NAME_AND_DOB: 'NAME_AND_DOB',
}

export const MANUAL_OVERRIDE_STATUSES = [
  { label: 'Manually Passed', value: 'PASS' },
  { label: 'Manually Failed', value: 'FAIL' },
]

export const WATCHLIST_REASON_CODE_OPTIONS = [
  { label: 'Previously Blocklisted', value: 'WAS_BLACKLISTED' },
  { label: 'Suspected Fraud', value: 'SUSPECTED_FRAUD' },
  { label: 'Suspected ID Theft', value: 'SUSPECTED_ID_THEFT' },
  { label: 'ID Theft Victim', value: 'ID_THEFT_VICTIM' },
  { label: 'Suspicious Data', value: 'SUSPICIOUS_DATA' },
  { label: 'SMR Raised', value: 'SMR_RAISED' },
  { label: '2x SMR Reports Lodged', value: '2X_SMR_REPORTS_LODGED' },
]

export const requireConsentFlagsForAllCustomer = [
  'general',
  'docs',
  'creditheader',
]
export const requireConsentFlagsWhenSplit = ['general']

export const TWILIO_LIST_OF_SUPPORTED_COUNTRIES = [
  'ARG',
  'AUS',
  'AUT',
  'BEN',
  'BIH',
  'BGR',
  'BRA',
  'BRB',
  'CAN',
  'CHE',
  'CHL',
  'CHN',
  'COL',
  'CYP',
  'CZE',
  'DEU',
  'DNK',
  'DOM',
  'DZA',
  'ECU',
  'EGY',
  'ESP',
  'EST',
  'FIN',
  'FRA',
  'GBR',
  'GRD',
  'GEO',
  'GHA',
  'GIN',
  'GRC',
  'HKG',
  'HUN',
  'IDN',
  'IRL',
  'IND',
  'ISL',
  'ITA',
  'JOR',
  'JAM',
  'JPN',
  'KEN',
  'KWT',
  'LBN',
  'LTU',
  'LUX',
  'MAC',
  'MLI',
  'MEX',
  'MUS',
  'MYS',
  'NLD',
  'NOR',
  'NZL',
  'OMN',
  'PAN',
  'PER',
  'PHL',
  'POL',
  'PRT',
  'ROU',
  'SAU',
  'SDN',
  'SGP',
  'SRB',
  'SWE',
  'THA',
  'TTA',
  'TZA',
  'UGA',
  'USA',
  'VNM',
  'VNM',
  'ZAF',
]

export const OPTIONS_ONBOARDING = {
  PEP: 'PEP',
  S: 'Sanctions',
  WL: 'Watchlist',
  M: 'Adverse Media',
  DUP: 'Duplicate',
  BL: 'Blocklist',
  RISK: 'Risk',
  IWL: 'Internal Watchlist',
  'KYC,PTL,404': 'KYC',
  500: 'Timeout',
  'ATT-OCR,ATT-IDV,ATT-PHT,ATT-NCMP': 'Biometrics',
  'ATT-FACE': 'Facial Duplicate',
  'SUPPORT-DOCS': 'Document',
  'digital-id': 'Digital ID',
  FRAUD: 'Fraud',
  DEVICE: 'Device',
}

export const OPTIONS_MONITORING = {
  PEP: 'PEP',
  S: 'Sanctions',
  WL: 'Watchlist',
  M: 'Adverse Media',
}

export const OPTIONS_TRANSACTIONS = {
  TXN_CUSTOMER: 'Device/Customer',
  TXN_AML: 'AML Transactions',
  TXN_FRAUD: 'Fraud Transactions',
}
export const SEND_KYC_LINK_SUPPORTED_COUNTRIES = ['AUS']

export const EMPTY_ISSUES = [
  'txn_fraud',
  'txn_aml',
  'txn_customer',
  '500',
  'RSK',
  'rsk',
  'ATT-NCMP',
  'att-ncmp',
  'att-face',
  'support-docs',
  'SUPPORT-DOCS',
  'digital-id',
  'fraud',
  'device',
  'error',
  'aml',
  'blacklist',
]
