import mapRouteToUrl from '@/utils/routeToUrlMapper'
import axios from 'axios'
import { axiosConfigForNode } from '@/api/axiosConfig'

export async function getSettings() {
  const url = mapRouteToUrl('ffportal_admin_settings', {})

  return axios.get(url, { ...axiosConfigForNode }).then(res => {
    return res.data
  })
}
export async function updateSettings(payload) {
  const { name, value } = Object.assign({}, payload)
  const url = mapRouteToUrl('ffportal_admin_settings', {})

  return axios
    .post(
      url,
      {
        setting: { name, value },
      },
      axiosConfigForNode,
    )
    .then(res => {
      return res.data
    })
}
