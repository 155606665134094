import idNumber from './fields/idNumberField'
import idExpiry from './fields/idExpiryField'
import TextInput from '@/components/inputs/TextInput'
import { MedicareColorSelect } from '@frankieone/ui-components'
import MedicareNameInput from '@/components/smart-inputs/MedicareNameInput.vue'
import { DISPLAY_DATE_FORMAT, DISPLAY_MONTH_FORMAT } from '@/constants.js'
import common from '@frankieone/shared'
import {
  formatDate,
  getMedicareColor,
} from '@/components/builders/documentForm/DocumentViewTranform.ts'
import mergeBlueprint from '@/utils/mergeBlueprint.js'
const { validator } = common
let blueprint = {
  fields: {
    idSubType: {
      component: MedicareColorSelect,
      props() {
        return {
          label: 'Card Colour',
          disabled: this.disabled,
          qa: 'color',
        }
      },
      width() {
        return '100%'
      },
      visibility() {
        return true
      },
      view() {
        return {
          title: 'Card Colour',
          value: getMedicareColor(this.value),
        }
      },
    },
    idNumber: {
      ...idNumber({ placeholder: '10 digits', mask: '##########' }),
      visibility() {
        return true
      },
      view() {
        return {
          title: 'Card Number',
          value: this.fieldValue,
        }
      },
    },
    'extraData.reference': {
      component: TextInput,
      props() {
        return {
          placeholder: '1 digit',
          label: 'Position on Card',
          mask: '#',
          qa: 'reference',
        }
      },
      width() {
        return '25%'
      },
      visibility() {
        return true
      },
      view() {
        return {
          title: 'Position on Card',
          value: this.fieldValue,
        }
      },
    },
    idExpiry: {
      ...idExpiry,
      props() {
        const inherited = idExpiry.props.bind(this)()
        const isG = this.value.idSubType === 'G'
        return {
          ...inherited,
          mask: isG ? '##-####' : '##-##-####',
          minimumView: isG ? 'month' : 'day',
          placeholder: isG ? DISPLAY_MONTH_FORMAT : DISPLAY_DATE_FORMAT,
          qa: 'idExpiry',
        }
      },
      visibility() {
        return true
      },
      view() {
        const isG = this.value.idSubType === 'G'
        const dateFormat = isG ? DISPLAY_MONTH_FORMAT : DISPLAY_DATE_FORMAT
        return {
          title: 'Expiry Date',
          value: formatDate(this.fieldValue, dateFormat),
        }
      },
    },
    'extraData.display_middle_name': {
      component: MedicareNameInput,
      props() {
        const displayMiddleName =
          this.value?.extraData?.display_middle_name || null
        return {
          placeholder: '',
          label: 'How is middle name displayed on the card?',
          qa: 'reference',
          value: displayMiddleName,
          middleName: this.entityName?.middleName,
          disabled: !this.entityName?.middleName,
        }
      },
      width() {
        return '50%'
      },
      visibility() {
        return true
      },
    },
    'extraData.displayMiddleName': {
      component: TextInput,
      props() {
        const middleDisplay = this.value?.extraData?.display_middle_name || null
        const entityName = this.entityName || {}
        const medicareDisplayName = [
          entityName.givenName,
          middleDisplay,
          entityName.familyName,
        ]
          .filter(Boolean)
          .join(' ')
        return {
          placeholder: '',
          label: 'Name as displayed on card',
          value: medicareDisplayName,
          disabled: true,
        }
      },
      view() {
        const middleDisplay = this.value?.extraData?.display_middle_name
        const entityName = this.applicant?.name || {}
        const medicareDisplayName = [
          entityName.givenName,
          middleDisplay,
          entityName.familyName,
        ]
          .filter(Boolean)
          .join(' ')
        return {
          title: 'Full Name on Card',
          value: medicareDisplayName,
        }
      },
      width() {
        return '50%'
      },
      visibility() {
        return true
      },
    },
  },
}

blueprint = mergeBlueprint('MEDICARE', blueprint)
export { blueprint }
