import Vue from 'vue'
import ForbiddenModal from '@/components/widgets/ForbiddenModal'
import routeToUrl from '@/utils/routeToUrlMapper'

const redirectToLogin = () =>
  (window.location.href = routeToUrl('ffportal_login'))
let isDup = false
export const checkFetchError =
  (options = {}) =>
  async response => {
    if (response.handledByFetchErrorHandler)
      console.error('Calling fetchErrorHandler on Response twice. Remove that.')

    options = Object.assign(
      {},
      {
        json: true,
      },
      options,
    )

    let result = {}

    result.status = response.status
    result.handledByFetchErrorHandler = true

    if (result.status !== 204) {
      // no content
      if (options.json) {
        try {
          const data = await response.json()
          result = JSON.parse(JSON.stringify(data))
          // TODO: need to remove this asap and bring data into result.data (below). After this check and uncomment unit tests!
          // result.data = JSON.parse(JSON.stringify(data));
        } catch (e) {
          console.error('Invalid JSON body.')
        }
      } else result = response
    }

    switch (response.status) {
      case 403:
        if (isDup) return
        isDup = true
        new Vue({}).$modal.show(
          ForbiddenModal,
          {},
          { pivotX: 0.5, pivotY: 0.5, height: '429px', width: '940px' },
          {
            name: 'dynamic-modal',
            closed: () => {
              isDup = false //reset the isDup when modal is close
            },
          },
        )
        break
      case 302:
        return redirectToLogin()
    }
    if (response.redirected === true) return redirectToLogin()

    if (!response.ok) throw result
    else return result
  }
