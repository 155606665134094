export const mkMutationName = (action, category, stateName) => {
  let icon
  if (action === 'set') icon = '✅'
  else if (action === 'clear') icon = '🔥'
  else if (action === 'concat') icon = '💏'

  action = action && action.toUpperCase()
  category = category && category.toUpperCase()
  stateName = stateName && stateName.toUpperCase()

  return icon + ' ' + [action, category, stateName].filter(i => i).join('_')
}
