export function mapMultipepMatchStatus(term) {
  const mapped = { needsAttention: false, needsAction: false }

  // unifying possible_match possible terms
  if ([null, undefined, '', 'possible_match'].includes(term))
    term = 'possible_match'

  // flag that it needs attention
  if (['possible_match', 'unknown'].includes(term)) mapped.needsAttention = true

  if (['true_positive'].includes(term)) mapped.needsAction = true

  switch (term) {
    case 'true_positive_accept':
    case 'true_positive_approve':
      term = 'true_positive_accept'
      break
    case 'true_positive_reject':
      term = 'true_positive_reject'
      break
  }
  // defining as final state
  switch (term) {
    case 'true_positive_reject':
    case 'true_positive_accept':
    case 'false_positive':
      mapped.type = 'final'
      break
    default:
      mapped.type = 'non-final'
  }

  mapped.term = term

  return mapped
}
export function sortMatchStatus(a, b) {
  const options = ['possible_match', 'unknown', 'true_positive']
  const getSortPoints = status => {
    let points = Infinity
    options.forEach((term, i) => {
      if (term === status.term) points = (i + 1) * 5
    })
    return points
  }
  // console.log(a, b)
  // console.log(getSortPoints(a), getSortPoints(b))
  return getSortPoints(a) - getSortPoints(b)
}
export const OPTIONS_MATCH = [
  { value: 'true_positive', label: 'True Positive' },
  { value: 'false_positive', label: 'False Positive' },
  { value: 'possible_match', label: 'Possible Match' },
  { value: 'unknown', label: 'Unknown' },
]
export const OPTIONS_AML = [
  { value: 'true_positive_accept', label: 'True Positive Approved' },
  { value: 'true_positive_reject', label: 'True Positive Rejected' },
]
export function getMatchStatusName(term) {
  const option = [...OPTIONS_MATCH, ...OPTIONS_AML].find(o => o.value === term)
  return option && option.label
}
