import TextInput from '@/components/inputs/TextInput'
import FullNameInput from '@/components/smart-inputs/FullNameInput'
import {
  makeLengthValidator,
  makeRequiredValidator,
  makeDateValidator,
} from '@/utils/validators/basicValidators'

export default function (options) {
  const { givenNamePath, familyNamePath, otherNamePath, label, fieldName } =
    Object.assign(
      {
        givenNamePath: 'registered_name_first_given',
        familyNamePath: 'registered_name_family',
        otherNamePath: 'registered_name_other_given',
        label: 'Registered Name',
        fieldName: 'full-name',
      },
      options,
    )

  return {
    [`extraData.${givenNamePath}`]: {
      validator(value) {
        if (this.value.country !== 'AUS') {
          return true
        }

        const required = makeRequiredValidator()
        const length = makeLengthValidator(1, 50)

        return required(value) && length(value)
      },
    },
    [`extraData.${otherNamePath}`]: {
      validator(value) {
        if (this.value.country !== 'AUS') {
          return true
        }

        const length = makeLengthValidator(1, 100)

        return !value || length(value)
      },
    },
    [`extraData.${familyNamePath}`]: {
      validator(value) {
        if (this.value.country !== 'AUS') {
          return true
        }

        const required = makeRequiredValidator()
        const length = makeLengthValidator(1, 50)

        return required(value) && length(value)
      },
    },
    [fieldName]: {
      component: FullNameInput,
      view() {
        const givenName = this.getFieldValue('extraData.' + givenNamePath)
        const otherGivenName = this.getFieldValue('extraData.' + otherNamePath)
        const familyName = this.getFieldValue('extraData.' + familyNamePath)
        return {
          title: label,
          value: this.getFullName(givenName, otherGivenName, familyName),
        }
      },
      width() {
        return '100%'
      },
      props() {
        return {
          boldLabel: true,
          value: {
            given: this.value.extraData[givenNamePath],
            other: this.value.extraData[otherNamePath],
            family: this.value.extraData[familyNamePath],
          },
          label,
          separateInputs: true,
          fieldName,
          issues: (() => {
            const allIssues = this.issues
            return (
              allIssues
                // filter issues to the three name fields
                .filter(issue =>
                  [
                    `extraData.${givenNamePath}`,
                    `extraData.${otherNamePath}`,
                    `extraData.${familyNamePath}`,
                  ].includes(issue),
                )
                // map issues to the format expected by full name input component
                .map(issue => {
                  let mapped
                  switch (issue) {
                    case `extraData.${givenNamePath}`:
                      mapped = 'given'
                      break
                    case `extraData.${otherNamePath}`:
                      mapped = 'other'
                      break
                    case `extraData.${familyNamePath}`:
                      mapped = 'family'
                      break
                  }
                  return mapped
                })
            )
          })(),
        }
      },
      eventListeners() {
        return {
          input: ({ given, other, family }) => {
            const value = { ...this.value }
            value.extraData[givenNamePath] = given
            value.extraData[otherNamePath] = other
            value.extraData[familyNamePath] = family
            this.$emit('input', value)
          },
        }
      },
      visibility() {
        return this.value.country === 'AUS'
      },
      required() {
        return true
      },
    },
  }
}
