<template lang="pug">
    label-wrapper.filter-list-pick-input( :label='label' :class="[{'is-invalid': !validation, disabled, 'input-focus': isFocus }]" :displayV2="displayV2")
        v-autocomplete.content__centered.basic-input-style(
            ref="autocomplete"
            :clearable="clearable"
            no-data-text="No match" :placeholder="placeholder"
            :class="[{'is-invalid': !validation }]"
            :items='finalOptions' item-text='label' :disabled='disabled'
            :id='id'
            :data-qa='qa'
            @focus='isFocus = true'
            loader-height='48px'
            dense
            :attach='attach'
            :menu-props='menuProps'
            @blur='blur'
            @update:search-input="filterData = $event" item-value='value'
            :value='value.value || value' @change="onChange" solo hide-details flat :return-object="!emitValueOnly")
            template(v-slot:append)
                simple-icon.arrow(icon="arrow-toggle" :overrideColor='COLOR__DARK_NAVY_60' padding='0' size="14px" )
</template>
<style lang="sass" scoped>
$border-color: $COLOR__GREY
$placeholder-color: $COLOR__TEXT_GREY
$label-color: $placeholder-color
$text-color: $COLOR__BLACK
$paddings: $LENGTH__SM_PADDING
.arrow
    transition: 0.3s
.isInputFocus .arrow
    transform: rotate(-180deg)
.disabled
    cursor: not-allowed
.filter-list-pick-input
    position: relative
.input-focus .basic-input-style
    +input-style(focus)
.is-invalid .basic-input-style
    +input-style(error)
.content__centered
  align-items: center
.filter-list-pick-input::v-deep
    .v-menu__content
        @extend %custom-list-pick-input
        top: 64px !important
    input
        font-weight: 600
        font-size: 14px
    .basic-input-style
        margin: 0 !important
        padding: 0!important
    .v-input__slot
        margin: 0 !important
        input
            font-size: 14px !important
            line-height: 150%!important
            color: $COLOR__DARK_NAVY!important
        &::before
            display: none !important
        &::after
            display: none !important
        & input
            color: $COLOR__DARK_NAVY!important
    .v-text-field__details
        display: none !important
    .v-list-item
        height: 44px!important
    .v-input__append-inner
        // display: none
    .v-input__append-innert
        display: none
    .v-input__slot, .v-input__control
        min-height: auto!important
    .v-input__control
        min-height: auto!important
    .v-input__icon--clear
        padding-top: 5px!important


    &.disabled
        & .v-input, & .v-input__slot, & input
            background-color:  $COLOR__BACKGROUND_GREY!important
            cursor: not-allowed
            color: $COLOR__DARK_NAVY_40!important
    &.disabled
        & .v-input__slot input, & .v-select__selection
            color: $COLOR__DARK_NAVY_40!important
</style>

<script>
import InputMixin from './Input.mixin'
import ToggleMixin from './Toggle.mixin'
import LabelWrapper from '@/components/wrappers/LabelMessageWrapper.vue'
import { COLOR__DARK_NAVY_60 } from '@/styles/config2.sass'
import SimpleIcon from '@/components/widgets/SimpleIcon'
const findOptionByLabel = options => label =>
  options.find(el => el.label && el.label.toLowerCase() === label.toLowerCase())
const findOptionByValue = options => value =>
  options.find(el => el.value === value)

export default {
  constants: {
    COLOR__DARK_NAVY_60: COLOR__DARK_NAVY_60,
  },
  mixins: [InputMixin, ToggleMixin],
  components: {
    LabelWrapper,
    SimpleIcon,
  },
  data: () => ({
    innerFilter: '',
    isInputFocus: false,
  }),
  props: {
    disabled: Boolean,
    value: {
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    fullHeight: Boolean,
    includeFilterOption: Boolean,
    emptyValues: Array,
    id: String,
    qa: String,
    icon: String,
    attach: [String, HTMLDivElement],
    emitValueOnly: Boolean,
    displayV2: Boolean,
    clearable: Boolean,
    menuProps: Object,
  },
  computed: {
    filterData: {
      set(value) {
        this.$emit('update:filter', value)
        this.innerFilter = value
      },
      get() {
        // if (this.filter) return this.filter;
        return this.innerFilter
      },
    },
    attrs() {
      const { options, value, qaLabel, ...attrs } = {
        ...this.$attrs,
        ...this.$props,
      }

      return attrs
    },
    finalOptions() {
      const options = [...this.options.filter(o => !!o.value && !!o.label)]
      const filter = this.filterData

      if (
        this.includeFilterOption &&
        filter &&
        !findOptionByLabel(options)(filter)
      )
        options.push({ value: filter, label: filter })
      return options
    },
  },
  methods: {
    blur() {
      this.isFocus = false
      this.$refs.autocomplete.blur()
    },
    getOptionClass(option) {
      if (
        this.finalOptions.length !== this.options &&
        option.label === this.innerFilter
      ) {
        return 'italic'
      }
      return ''
    },
    onChange(data) {
      if (data === null && this.clearable) {
        data = { label: '', value: '' }
      }
      this.$emit('input', data)
    },
    selected(data) {
      let { label, value } = data

      if (!label) {
        const option = findOptionByValue(this.finalOptions)(value)
        label = option && option.label
      } else if (!value) {
        const option = findOptionByValue(this.finalOptions)(label)
        value = option && option.value
      }

      this.$emit('input', { label, value })

      this.filterChanged(label, { label, value })

      this.$nextTick(() => (this.isToggled = false))
    },
    filterChanged(filterText, data) {
      if (filterText || filterText === '') {
        this.filterData = filterText
      } else if (data) {
        // data is supposed to have label here
        const label = data.label
        this.filterData = label
      }

      if (!data) {
        this.$nextTick(() => {
          // if it's in options (disconsidering extra custom option added)
          const option = findOptionByLabel(this.options)(filterText)
          // then consider item selected
          if (option) this.$emit('input', option)
          // else this.$emit("input", { label: filterText, value: null });
        })
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function () {
        this.innerFilter =
          this.value?.label || this.value?.value || this.innerFilter
        this.$nextTick(() => (this.isToggled = false))
      },
    },
    filterData() {
      this.isToggled = true
      //   if (this.filter && this.filter.length > 0) this.isToggled = true;
      //   else this.isToggled = false;
    },
  },
}
</script>
