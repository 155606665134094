export const OPTIONS_ONBOARDING = [
  { value: 'LOW', label: 'Low' },
  { value: 'MEDIUM', label: 'Medium' },
  { value: 'HIGH', label: 'High' },
  { value: 'UNACCEPTABLE', label: 'Unacceptable' },
  // { value: 'SIGNIFICANT', label: 'Significant' },
  { value: 'UNKNOWN', label: 'Unknown' },
]

export const OPTIONS_TRANSACTIONS = [
  { value: 'UNKNOWN', label: 'Unknown' },
  { value: 'NONE', label: 'None' },
  { value: 'LOW', label: 'Low' },
  { value: 'MEDIUM', label: 'Medium' },
  { value: 'HIGH', label: 'High' },
  { value: 'VERY_HIGH', label: 'Very High' },
]

export function normalizeRiskData(key) {
  switch (typeof key) {
    case 'string':
      key = key.toLowerCase()
      break
    case 'object':
      return key
    default:
      key = 'unknown'
  }

  const norm = { key }
  switch (key) {
    case 'significant':
      norm.term = 'risk_significant'
      norm.label = 'Significant'
      break
    case 'unacceptable':
      norm.term = 'risk_unacceptable'
      norm.label = 'Unacceptable'
      break
    case 'low':
      norm.term = 'risk_low'
      norm.label = 'Low'
      break
    case 'medium':
      norm.term = 'risk_medium'
      norm.label = 'Medium'
      break
    case 'high':
      norm.term = 'risk_high'
      norm.label = 'High'
      break
    case 'unknown':
    default:
      norm.term = 'risk_unknown'
      norm.label = 'Unknown'
  }

  return norm
}
