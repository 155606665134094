<template lang="pug">
.module-view
    v-row.action-view-container(v-if="!loading" no-gutters, ref="action-view-container")
      v-col
        slot
</template>
<style lang="sass" scoped>
@import '~@/styles/config'
@import '~@/styles/scrollbar'
.module-view
  height: calc(100vh - #{$LENGTH__HEADER_HEIGHT})
  display: flex
  justify-content: center

.action-view-container
  width: 100%
  margin: 0 auto
  background: white
  border: 1px solid $COLOR__MID_GREY
  border-top: 0
.cardStyle
  width: calc(100% - 80px)
  margin-top: 30px
  border-radius: 8px
  margin-left: 40px
  margin-right: 40px
  margin-bottom: 30px
  padding-bottom: 1px
  &::v-deep .page-content
    height: 100%
</style>

<script>
import { addScript } from '@/utils/addScriptToHeader.js'
const isProd = process.env.NODE_ENV === 'production'
import { mapActions } from 'vuex'
import { getToken } from '@/utils/pageDataStorage'

export default {
  data() {
    return {
      loading: false,
    }
  },
  mounted() {
    const isLogin = !!this.pageData?.user
    if (isProd && isLogin) {
      setTimeout(() => {
        addScript(
          {
            src: 'https://jsd-widget.atlassian.com/assets/embed.js',
            'data-jsd-embedded': true,
            'data-key': '39741c81-e9c3-4b94-91ae-c08e292085e7',
            'data-base-url': 'https://jsd-widget.atlassian.com',
          },
          null,
          function () {
            const domContentLoaderEvent = new Event('DOMContentLoaded')
            window.document.dispatchEvent(domContentLoaderEvent)
          },
        )
      }, 300)
    }
  },
  methods: {
    ...mapActions(['getSettings']),
  },
  async created() {
    if (getToken()) {
      try {
        this.loading = true
        this.$ev.$emit('startLoading')
        await this.getSettings()
      } catch (e) {
        this.$ev.$emit('stopLoading')
      } finally {
        this.$ev.$emit('stopLoading')
        this.loading = false
      }
    }
  },
}
</script>
